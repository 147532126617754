import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CircleLoader } from "../../../../../components/Loader/CommonLoader"
import { faCircleCheck, faCodeFork, faEdit, faFileArrowUp, faPlus, faScissors, faTrash, faTrashAlt, faXmark } from "@fortawesome/free-solid-svg-icons"
import CustomeAutoComplete from "../../../../../components/CustomeAutoComplete/CustomeAutoComplete"
import TooltipButton from "../../../../../components/TooltipButton/TooltipButton"
import classNames from "classnames"
import { toast } from "react-toastify"
import AxiosService from "../../../../../utils/AxiosService"
import { useEffect, useState, useRef } from "react"
import styles from  "./StepTwoForm.module.scss";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons"
import { useSelector } from "react-redux"


const TableItem = ({
	manuscriptId,btnLoading, getData, fileData, 
	index, handleDownloadFile, removeBtnLoading, 
	selectedFile, handleDeleteFile, getCaptions, 
	figureCaptionList, figureCaptionLoading, setMyFiles, 
	options, allowedDesinationFiles, fileCheckBoxChange,
	isChecked, deleteFileIds, resetError, splitFunction,
	setSplitModal, splitModal
}) => {
	const [updatedCitation, setUpdatedCitation] = useState("")
	const [isEditable, setIsEditable] = useState(false)
	const apiService = new AxiosService()
    const [isUpdateLoading, setIsUpdateLoading] = useState(false)
    const [error, setError] = useState("")
	const inputFile = useRef(null);
	const [rowData, setRowData] = useState(fileData)
	const [file, setFile] = useState()
	const [directFileUpload, setDirectFileUpload] = useState(false)
	const [deleteLoader, setDeleteLoader] = useState(false)
	const [editLoader, setEditLoader] = useState(false)
	const [splitLoader, setSplitLoader] = useState(false)
	const [showFullCaption, setShowFullCaption] = useState(false)
	const [showFullFileName, setShowFullFileName] = useState(false)
	const [submitRowError, setSubmitRowError] = useState(false)
	const [deleteExtraFileLoading, setDeleteExtraFileLoading] = useState(false)
	const journalConfig = useSelector(store => store.journalConfig.config)

	const handleSelect = (selectedValue) => {
		setRowData({...rowData, caption: selectedValue})
	}

	function handleEdit(val) {
		setUpdatedCitation(val.caption)
		setIsEditable(true)
	}

	const generateFileString = (filesArray) => {
		let lastElement = filesArray.pop().toUpperCase()
		let returnString = filesArray.join(", ").toUpperCase()
		filesArray.push(lastElement)
		return returnString + ", or " + lastElement
	}

	function submitRow () {
		setDirectFileUpload(false)
		setEditLoader(true)
		if(rowData.fileDesignation.length < 1) {
			toast.error("Please provide a file designation.", {autoClose: 3000})
			setEditLoader(false)
			setRowData({...rowData, fileName: ""})
			return
		// } else if (rowData.lastRow && rowData.fileName.length < 1) {
		// 	toast.error("File is required")
		// 	setRowData({...rowData, fileName: ""})
		// 	setEditLoader(false)
		} else if (rowData?.file?.name?.length > 0) {
			let ext = rowData.file?.name.split(".").pop().toLowerCase()
			let filesAllowed = allowedDesinationFiles.filter(obj => obj.name === rowData.fileDesignation)
			if(filesAllowed[0]?.files[0] !== "ALL FILE FORMATS" && !filesAllowed[0]?.files?.includes(ext.toUpperCase()) && rowData.fileDesignation !=="Title page" && rowData.fileDesignation !=="Copy right agreement" && rowData.fileDesignation !=="Other" && rowData.fileDesignation !=="Form") {
				toast.error(`Files must be ${generateFileString(filesAllowed[0]?.files)}`, {autoClose: 3000})
				setRowData({...rowData, fileName: ""})
				setEditLoader(false)
			} else {
				// if(rowData?.fileDesignation === "Figure" && (ext !== "jpeg" && ext !== "jpg" && ext !== "png" && ext !== "tiff" && ext !== "tif") ) {
				// 	toast.warn("Please note that DPI and composite images will be checked for JPEG, JPG, PNG, TIFF, and TIF files.", {autoClose: 10000, progressClassName: "toastProgressBar10s"})
				// }
				handleUpdate()
			}
			return
		} else if (journalConfig?.captionRequired?.includes(rowData?.fileDesignation) && !rowData?.caption?.trim()?.length > 0) {
			toast.error(`${rowData.fileDesignation} caption cannot be blank.`, {autoClose: 3000})
			setRowData({...rowData, fileName: ""})
			setEditLoader(false)
			return
		} else {
			let ext = rowData.file?.name.split(".").pop()
			// if(rowData?.fileDesignation === "Figure" && (ext !== "jpeg" && ext !== "jpg" && ext !== "png" && ext !== "tiff" && ext !== "tif") ) {
			// 	toast.warn("Please note that DPI and composite images will be checked for JPEG, JPG, PNG, TIFF, and TIF files.", {autoClose: 10000, progressClassName: "toastProgressBar10s"})
			// }
			handleUpdate()
		}
	}

	function handleUpdate(){
		let ext = rowData.file?.name.split(".").pop().toLowerCase()
        setIsUpdateLoading(true)
		// let formData = new FormData() 
		let requestBody = rowData
		let body = {}

		// if(requestBody.file !== undefined) {
		// 	formData.append("file", requestBody.file)
		// }

		if(requestBody.fileId !== undefined) {
			// formData.append("fileId", requestBody.fileId)
			body.fileId = requestBody.fileId
		}

		if(requestBody.caption !== undefined && journalConfig?.captionRequired?.includes(rowData?.fileDesignation)) {
			// formData.append("caption", requestBody.caption)
			body.caption = requestBody.caption
		}

		if (rowData.fileDesignation !== undefined) {
			// formData.append("fileDesignation", rowData.fileDesignation)
			body.fileDesignation = rowData.fileDesignation
		}

		// if (rowData.fileName !== undefined && !editRow) {
		// 	formData.append("fileName", rowData.fileName)
		// }

		apiService.uploadStepTwoFileInfoWithCaption(manuscriptId, body).then((res) => {
			if(rowData.lastRow) {
				delete rowData.lastRow
			}
			setEditLoader(false)
			toast.success(res.result, {autoClose: 3000})
			if(rowData.fileDesignation === "Figure" && (ext !== undefined && ext !== "jpeg" && ext !== "jpg" && ext !== "png" && ext !== "tiff" && ext !== "tif") ) {
				toast.warn("Please note that DPI and composite images will be checked for JPEG, JPG, PNG, TIFF, and TIF files.", {autoClose: 10000, progressClassName: "toastProgressBar10s"})
			}
			getData()
			setIsEditable(false)
		}, (err) => {
			console.log("Error in file update: ", err, err?.response?.data)
			toast.error(err?.response?.data?.message, {autoClose: 3000})
			if (rowData.lastRow) {
				setRowData({...rowData, fileName: ""})
				delete rowData.file
			} else {
				setSubmitRowError(true)
			}
			setEditLoader(false)
		})
		setIsUpdateLoading(false)
	}

	function uploadAdditionalFile(e) {
		setIsUpdateLoading(true)
		let formData = new FormData() 
		let requestBody = rowData
		let ext = e.target.files[0]?.name.split(".").pop().toLowerCase()
		let filesAllowed = allowedDesinationFiles.filter(obj => obj.name === rowData.fileDesignation)
		if(requestBody.fileId !== undefined) {
			formData.append("fileId", requestBody.fileId)
		}
		if(e.target.files[0]) {
			formData.append("file", e.target.files[0])
		}
		if(filesAllowed[0]?.files[0] !== "ALL FILE FORMATS" && !filesAllowed[0]?.files?.includes(ext.toUpperCase()) && rowData.fileDesignation !=="Title page" && rowData.fileDesignation !=="Copy right agreement" && rowData.fileDesignation !=="Other" && rowData.fileDesignation !=="Form") {
			toast.error(`Files must be ${generateFileString(filesAllowed[0]?.files)}`, {autoClose: 3000})
			e.target.value = null
			setIsUpdateLoading(false)
			setEditLoader(false)
			return
		}
		if(e.target.files[0].size > 52428800) {
			toast.error("File exceeded the size limit. Uploaded files must not be more than 50 MB", {autoClose: 3000})
			e.target.value = null
			setIsUpdateLoading(false)
			setEditLoader(false)
			return
		}
		// if(rowData?.fileDesignation === "Figure" && (ext !== "jpeg" && ext !== "jpg" && ext !== "png" && ext !== "tiff" && ext !== "tif") ) {
		// 	toast.warn("Please note that DPI and composite images will be checked for JPEG, JPG, PNG, TIFF, and TIF files.", {autoClose: 10000, progressClassName: "toastProgressBar10s"})
		// }
		apiService.uploadExtraFile(manuscriptId, formData).then((res) => {
			if(rowData.lastRow) {
				delete rowData.lastRow
			}
			setIsUpdateLoading(false)
			setEditLoader(false)
			toast.success(res.result, {autoClose: 3000})
			if(rowData?.fileDesignation === "Figure" && (ext !== undefined && ext !== "jpeg" && ext !== "jpg" && ext !== "png" && ext !== "tiff" && ext !== "tif") ) {
				toast.warn("Please note that DPI and composite images will be checked for JPEG, JPG, PNG, TIFF, and TIF files.", {autoClose: 10000, progressClassName: "toastProgressBar10s"})
			}
			getData()
			resetError()
			setIsEditable(false)
			e.target.value = null
		}, (err) => {
			console.log("Error in file update: ", err, err?.response?.data)
			toast.error(err?.response?.data?.message, {autoClose: 3000})
			if (rowData.lastRow) {
				setRowData({...rowData, fileName: ""})
				delete rowData.file
			} else {
				setSubmitRowError(true)
			}
			setEditLoader(false)
			setIsUpdateLoading(false)
			e.target.value = null
		})
		// setIsUpdateLoading(false)
	}

	function deleteAdditionalFile (file, index) {
		setDeleteExtraFileLoading(true)
		const body = {
			fileId: file.fileId,
			fileName: file.fileName[index]
		}
		apiService.deleteExtraFile(manuscriptId, body).then((res) => {
			toast.success(res.result, {autoClose: 3000})
			setDeleteExtraFileLoading(false)
			resetError()
			getData()
		}).catch((err) => {
			toast.success(err?.respone?.data?.result, {autoClose: 3000})
			setDeleteExtraFileLoading(false)
			console.log("Error in delete file: ", err?.response)
		})
	}

	function handleValidation(e) {
		const {name , value} = e.target
		switch(name) {
			case "citationText": 
				setError(
					value?.length === 0 ? "*Caption is required!" : value?.trim().length===0 ? "Whitespaces not allowed"  : "" 
				)
			break
			default :
		}
	}

	function handleInputChange(e) {
		if(e.target.name === "fileUpload") {
			if(rowData.fileId) {
				uploadAdditionalFile(e)
			} else {
				setRowData({...rowData, fileName: [e.target.files[0].name], file: e.target.files[0]})
				setFile(e.target.files[0])
				if(!isEditable && !rowData.lastRow) {
					setDirectFileUpload(true)
				}
			}
		} else {
			if(e.target.name === "fileDesignation") {
				let notAllowedFiles = []
				if(rowData?.fileName?.length > 0) {
					rowData?.fileName?.map((item) => {
						let ext = item.split(".").pop().toLowerCase()
						let filesAllowed = allowedDesinationFiles.filter(obj => obj.name === e.target.value)
						if (filesAllowed[0]?.files[0] !== "ALL FILE FORMATS" && 
							!filesAllowed[0]?.files?.includes(ext.toUpperCase()) && 
							e.target.value !=="Title page" && 
							e.target.value !=="Copy right agreement" && 
							e.target.value !=="Other" && 
							e.target.value !=="Form"
						) {
							notAllowedFiles.push(item)
						}
					})
					if(notAllowedFiles.length > 0) {
						toast.error("Following files are not allowed for " + e.target.value + " file designation. " + generateFileString(notAllowedFiles), {autoClose: 5000, progressClassName: "toastProgressBar5s"})
					} else if (e.target.value === "Tables") {
						if(parseInt(journalConfig?.tablesNumberPerDesignation) < rowData?.fileName?.length) {
							toast.error(`Maximum number of files per designation exceeded. Please remove files to match the limit: ${parseInt(journalConfig?.tablesNumberPerDesignation)}`, {autoClose: 5000, progressClassName: "toastProgressBar5s"})
						} else {
							setRowData({...rowData, [e.target.name]: e.target.value})	
						}
					} else {
						setRowData({...rowData, [e.target.name]: e.target.value})
					}
				} else {
					setRowData({...rowData, [e.target.name]: e.target.value})
				}
			} else {
				setRowData({...rowData, [e.target.name]: e.target.value})
			}
		}
	}

	function deleteFile(file) {
		setDeleteLoader(true)
		const body= {
			"fileId": file?.fileId
		}
		apiService.deleteFile(manuscriptId, body).then(res=>{
			toast.success(res.result, {autoClose: 3000})
			getData();
			setDeleteLoader(false)
			resetError()
		}).catch(err => {
			console.log("Error in file delete: ", err.response)
			toast.error(err?.response?.data?.message, {autoClose: 3000})
			setDeleteLoader(false)
		})
	}

	function splitCaption (file) {
		setSplitLoader(true)
		const body = {
			"fileId": file?.fileId
		}
		apiService.splitFigurecaption(manuscriptId, body).then((res) => {
			toast.success(res.result, {autoClose: 3000})
			getData();
			setSplitLoader(false)
			resetError()
		}).catch((err) => {
			console.log("Error in splitting figure caption: ", err.response)
			toast.error(err?.response?.data?.message, {autoClose: 3000})
			setSplitLoader(false)
		})
	}

	function handleCancle () {
		setRowData(fileData)
		setIsEditable(false) 
	}

	const uploadFileClick = () => {
		if(rowData?.fileDesignation === "Tables") {
			if(parseInt(journalConfig?.tablesNumberPerDesignation) <= rowData?.fileName?.length) {
				toast.error("Maximum number of files per designation exceeded. Please upload the files for the new designation.", {autoClose: 5000, progressClassName: "toastProgressBar5s"})
			} else {
				inputFile.current.click()
			}
		} else {
			inputFile.current.click()
		}
	}

    useEffect(()=> {
        if(btnLoading){
            setIsEditable(false)
        }
    }, [btnLoading])

	useEffect(() => {
		if(directFileUpload === true) {
			submitRow()
		}
	}, [directFileUpload])

	return (
		<>
			<tr key={index} className={rowData?.lastRow ? "border border-top-0" : "border border-bottom-0 border-top-0 "}>
				
				{/* Index cell */}
				<td className="">
					{ !rowData?.lastRow ? 

						<div className="d-flex flex-row align-items-center justify-content-between">
							<input 
								aria-label={`Select ${rowData?.fileDesignation}`}
								type="checkbox" 
								className="mr-3"
								onChange={(e) => {fileCheckBoxChange(e)}}
								checked={isChecked(rowData.fileId)}
								value={rowData.fileId}
							/> 
							{index+1}
						</div>
						:
						<div className="d-flex justify-content-end">
							{index+1}
						</div>
					}
				</td>
				
				{/* File designation cell */}
				<td>
					{!rowData?.lastRow && rowData?.fileDesignation}
					{/* {isEditable === false && !rowData?.lastRow && rowData?.fileDesignation}
					{isEditable && <select className="form-control"
							name="fileDesignation" 
							value={rowData?.fileDesignation} 
							onChange={(e)=> handleInputChange(e)}
						>
							<option value="">Select file designation</option>
							{options.map(fileType=> (
								<option key={fileType.id} value={fileType.name}>{fileType.name}</option>
							))}
						</select>
					} */}
					{rowData?.lastRow &&
						<select className="form-control"
						aria-label="Select file designation"
						name="fileDesignation" 
						value={rowData?.fileDesignation} 
						onChange={(e)=> handleInputChange(e)}
						// onBlur={(event)=> handleValidation(event)}
						>
							<option value="">Select file designation</option>
							{options.map(fileType=> (
								<option key={fileType.id} value={fileType.name}>{fileType.name}</option>
							))}
						</select>
					}
				</td>

				{/* File upload cell */}
				{/* <td>
					{ rowData?.fileName.length > 0 && isEditable === false ?
						<div className="d-flex flex-column">
							<p 
								className={classNames(styles.downloadLink, "m-0")} 
								onClick={(e)=> handleDownloadFile(e, rowData?.fileName)}
							>
								{rowData?.fileName?.length > 9 ? 
									<>
										{showFullFileName ? rowData?.fileName : `${rowData?.fileName?.substring(0,9)+"..."}`}
									</>
									:
									rowData?.fileName
								}
							</p>
							{rowData?.fileName?.length > 9 && <button className="btn btn-sm btn-link p-0 align-self-start" onClick={() => {setShowFullFileName(!showFullFileName)}}>{showFullFileName ? "Hide" : "View more"}</button>}
						</div>
						:
						<>
							{ rowData?.fileName?.length > 0 ? 
								<div className="d-flex flex-row align-items-center">
									<p 
										className={classNames(styles.downloadLink,"mb-2")} 
										onClick={(e)=> handleDownloadFile(e, rowData?.fileName)}
									>
										{rowData?.fileName}
									</p>
									<FontAwesomeIcon icon={faFileArrowUp} className="ml-2 btn btn-md btnOutline" onClick={() => inputFile.current.click()}/>
								</div> 
								: 
								<>
									<button
										onClick={() => inputFile.current.click()}
										className="btn-sm btnOutline"
										disabled={isUpdateLoading}
									>
										{rowData?.lastRow ? "Attach file" : isEditable ? "Attach file" : "Upload file"}
									</button>
								</>
							}
							<input type="file" name = "fileUpload" onChange={(e) => handleInputChange(e)} ref={inputFile} />
						</>
					}
				</td> */}

				{/* File citation cell */}
				<td>
				{ !rowData?.lastRow && rowData?.referenceFormat !== "undefined" && journalConfig?.captionRequired?.includes(rowData?.fileDesignation) ?
					!isEditable ? 
						<div className="d-flex flex-column">
							<p className="m-0">
								{rowData?.caption?.length > 100 ? 
									<>
										{showFullCaption ? rowData?.caption : `${rowData?.caption?.substring(0,100) + "..."}`}
									</>
									:
									rowData?.caption
								}
							</p>
							{rowData?.caption?.length > 100 && <button className="btn btn-sm btn-link p-0 align-self-start" onClick={() => {setShowFullCaption(!showFullCaption)}}>{showFullCaption ? "Hide" : "View more"}</button>}
						</div>
					:
						<div classNames="d-flex justify-content-between align-items-center">
						<CustomeAutoComplete
							name ={'citationText'}
							placeholder='Enter file caption'
							searchValue={rowData?.caption} 
							getOptions={getCaptions}
							setSearchValue={(e)=> setRowData({...rowData, caption: e.target.value})} 
							handleSelect={handleSelect}
							optionList={figureCaptionList}
							loading={figureCaptionLoading}
							component="fileCaption"
							isEditable={true}
							onBlur={(e)=> handleValidation(e)}
							fileDesignation = {rowData?.fileDesignation}
						/>
					{/* {error ? <p className={classNames(styles.errorMessage, "mb-1")}>{error}</p> : ""}
					<button className="btn-sm mt-2 btnOutline" onClick={()=> handleUpdate(fileData)}>
					{isUpdateLoading ? <CircleLoader size={"sm"} /> : "Update"}
					</button>
					<button className="btnOutline btn-sm mt-2 ml-2" onClick={()=> setIsEditable(false)}>Cancel</button> */}
				</div>
				:
				rowData?.lastRow && journalConfig?.captionRequired?.includes(rowData?.fileDesignation) ? 
					<CustomeAutoComplete
						name ={'citationText'}
						placeholder='Enter file caption'
						searchValue={rowData?.caption} 
						getOptions={getCaptions}
						setSearchValue={(e)=> setRowData({...rowData, caption: e.target.value})} 
						handleSelect={handleSelect}
						optionList={figureCaptionList}
						loading={figureCaptionLoading}
						component="fileCaption"
						isEditable={true}
						onBlur={(e)=> handleValidation(e)}
						fileDesignation = {rowData?.fileDesignation}
					/>
				: 
				"-"
				}
				</td>

				{/* Action cell */}
				<td className="d-flex flex-row-reverse border-0">
					{ removeBtnLoading && selectedFile.fileName === rowData?.fileName ?
						<button className="border-danger text-danger">
							<CircleLoader size={"sm"}/>
						</button>
					:
						!rowData?.lastRow ? 
						<>
							{deleteLoader ? 
								!isEditable && <button className="btn btn-md text-danger "><CircleLoader size={"sm"}/></button>
								:
								!isEditable && 
								<TooltipButton tooltipMessage="Delete file entry">
									<button aria-label="Delete File" onClick={()=> deleteFile(rowData)} className="btn btn-md text-danger " disabled={isUpdateLoading || deleteExtraFileLoading || editLoader || deleteLoader || deleteFileIds.length}>
										<FontAwesomeIcon icon={faTrash} />
									</button>
								</TooltipButton>
							}
							{journalConfig?.captionRequired?.includes(rowData?.fileDesignation) ? 
								!isEditable ? 
									editLoader ? 
										<button aria-label="Loading" className={classNames(styles.fileTablePrimaryBtn, "btn btn-md")}><CircleLoader size={"sm"}/></button>
									:
										<TooltipButton tooltipMessage="Edit caption">
											<button aria-label="Edit file info" onClick={()=>setIsEditable(true)} disabled={isUpdateLoading || deleteExtraFileLoading || editLoader || deleteLoader || deleteFileIds.length} className={classNames(styles.fileTablePrimaryBtn, "btn btn-md")}>
												<FontAwesomeIcon icon={faEdit}/>
											</button>
										</TooltipButton>
								:
									editLoader ? 
										<>
											<TooltipButton tooltipMessage="Cancel">
												<button aria-label="Cancle Edit" className={classNames(styles.fileTablePrimaryBtn, "btn btn-md")} onClick={()=>setIsEditable(false)} disabled={isUpdateLoading || deleteExtraFileLoading || editLoader || deleteLoader || deleteFileIds.length}>
													<FontAwesomeIcon icon={faXmark}/>
												</button>
											</TooltipButton>
											<button aria-label="Loading" className={classNames(styles.fileTablePrimaryBtn, "btn btn-md")}><CircleLoader size={"sm"}/></button>
										</>
									:
										<>
											<TooltipButton tooltipMessage="Cancel">
												<button aria-label="Cancle Edit" onClick={()=>handleCancle()} disabled={isUpdateLoading || deleteExtraFileLoading || editLoader || deleteLoader || deleteFileIds.length} className={classNames(styles.fileTablePrimaryBtn, "btn btn-md")}>
													<FontAwesomeIcon icon={faXmark}/>
												</button>
											</TooltipButton>
											<button aria-label="Submit" onClick={()=>submitRow()} disabled={editLoader || deleteLoader || deleteFileIds.length} className={classNames(styles.fileTablePrimaryBtn, "btn btn-md")}>
												Submit
											</button>
										</>
							:
								null
							}
							

							{/* {splitLoader ? 
								!isEditable && <button className="btn btn-md text-warning "><CircleLoader size={"sm"}/></button>
								:
								!isEditable &&
								<TooltipButton tooltipMessage="Split figure caption">
									<button onClick={()=> setSplitModal({...splitModal, visible: true, data: rowData})} className="btn btn-md text-warning " disabled={editLoader || deleteLoader || deleteFileIds.length}>
										<FontAwesomeIcon icon={faCodeFork} className="fa-rotate-90"/>
									</button>
								</TooltipButton>
							} */}
						</>
						:
						<>
							{editLoader ? 
								<button className="btn btn-md btnOutline"><CircleLoader size={"sm"}/></button>
								:
								<button disabled={isUpdateLoading || deleteExtraFileLoading || editLoader || deleteLoader || deleteFileIds.length} onClick={()=> submitRow()} className={classNames(styles.fileTablePrimaryBtn, "btn btn-md p-0")}>
									Add file
								</button>
							}
						</>
						
					}
				</td>
			</tr>
			{!rowData?.lastRow && 
				<tr key={index+"1"} className="border border-top-0">
					<td colSpan={5} className="border-0 p-0">
						<div className="w-100 rounded py-2 px-3 mb-3">
							<div className="text-secondary mb-3 border-bottom p-1 d-flex flex-row justify-content-between align-items-center">
								Uploaded files
								<TooltipButton tooltipMessage="Upload file">
									<button className="btn-sm btnOutline" onClick={() => {uploadFileClick()}} disabled={isUpdateLoading || deleteExtraFileLoading || editLoader || deleteLoader || deleteFileIds.length || rowData.fileDesignation.length < 1}>
										{!isUpdateLoading && !editLoader ? <><FontAwesomeIcon icon={faFileArrowUp}/> Upload file</> : <CircleLoader size="sm"/>}
									</button>
								</TooltipButton>
								<input aria-label="Upload supplimentry file" type="file" name = "fileUpload" onChange={(e) => handleInputChange(e)} ref={inputFile} />
							</div>
							{rowData?.fileName?.length ? 
								<div className="d-flex flex-row flex-wrap">
									{rowData?.fileName?.map((item, index) => {
										return (
											<div className={classNames(styles.keywordSpan, "d-flex flex-row align-items-center")}>
												<span>
													{item}
													<TooltipButton tooltipMessage="Delete file">
														<button aria-label="Delete File" className={classNames(styles.btn, "py-0 pl-2 pr-0")} onClick={()=> deleteAdditionalFile(rowData, index)} disabled={isUpdateLoading || deleteExtraFileLoading || editLoader || deleteLoader || deleteFileIds.length}>
															<FontAwesomeIcon icon={faXmark}/>
														</button>
													</TooltipButton> 
												</span>
											</div>
										)
									})}
									{ deleteExtraFileLoading &&
										<div className={classNames(styles.keywordSpan2, "d-flex flex-row align-items-center")}>
											<span>
												<TooltipButton tooltipMessage="Delete file">
													<button aria-label="Loading" className={classNames(styles.btn, "py-0 pl-0 pr-0")}>
														<CircleLoader size="sm"/>
													</button>
												</TooltipButton> 
											</span>
										</div>
									}
								</div>
							:
								<span className="px-2 text-secondary">Files are not uploaded yet.</span>
							}
						</div>
					</td>
				</tr>
			}
		</>
	)
}

export default TableItem