import { faTrash, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { CircleLoader, FieldLoader } from "../../../../components/Loader/CommonLoader";
import AxiosService from "../../../../utils/AxiosService";
import ManuscriptItem from "../ManuscriptItem/ManuscriptItem";

const ManuscriptsContent = ({errorMessage, manuscripts, isLoading, status, handleRender, isRefreshed, setIsStatusChange, checkedVal, setCheckedVal, isCheckedAll, setIsCheckedAll, isDeleteAllLoading, setIsDeleteAllLoading, selectAllId}) => {
    const apiService = new AxiosService();
    const [showDeleteAll, setShowDeleteAll] = useState(false);

    function onChange(event) {
        setIsCheckedAll(v => !v)
        const localIsChecked = event.target.checked;
        if (localIsChecked) {
            let allManuscripts = [] = manuscripts.map(m => m.manuscriptId)
            setCheckedVal(allManuscripts);
        } else {
            setCheckedVal([]);
        }
    }

    const handleDelete = (e) => {
        e.preventDefault()
        setIsDeleteAllLoading(true)
        if(checkedVal.length >0) {
            apiService.deleteAllManuscript({
                "ids": checkedVal
            }).then(response => {
                setShowDeleteAll(false)
                setIsDeleteAllLoading(false)
                toast.success(response.result, {
                    autoClose: 3000,
                })
                handleRender([])
                setIsCheckedAll(false)
            }).catch(error=> {
                setShowDeleteAll(false)
                setIsDeleteAllLoading(false)
                toast.error(error?.response?.data?.message,  {
                    autoClose: 3000,
                })
            })
        }
    }
    
    return (
        <div className="py-2 pl-2">
        { isLoading ?
        <div className="text-center"><CircleLoader /></div>
        :manuscripts.length !==0 ?
        <>
        <div className="d-flex justify-content-between align-items-center border-bottom pb-2">
           <div>
            <input type="checkbox"
             id={selectAllId}
             aria-label="dashboard-select-all-checkbox"
             className="mr-2"
             onChange={e => onChange(e)}
             value={isCheckedAll}
             checked={isCheckedAll} />
            <label htmlFor={selectAllId}>{!isCheckedAll ? "Select all" : "Unselect all"}</label> <br/>
            {status === "Completed" ? <span className="text-secondary small">Use the status bar below each manuscript to update the progress of your manuscript</span> : "" }
           </div>
           <div>
            <button className="btn btn-danger btn-sm p-1" onClick={()=> setShowDeleteAll(true)} disabled={!checkedVal?.length >0}>Delete selected manuscript ({checkedVal.length}) <FontAwesomeIcon icon={faTrashCan} /></button>
           </div>
        </div>
        {manuscripts.map(manuscript => (
            <ManuscriptItem checkedVal={checkedVal} setCheckedVal={setCheckedVal} key={manuscript.manuscriptId} manuscript={manuscript} status={status} handleRender={handleRender} isRefreshed={isRefreshed} setIsStatusChange={setIsStatusChange} />
        ))}
        </>
        :
        errorMessage ? <p className="text-danger font-weight-bold">{errorMessage}</p> : <p>Manuscripts not found!</p>
        }

        <Modal show={showDeleteAll} onHide={()=> setShowDeleteAll(false) } size="sm">
            <Modal.Header  className="h6">Delete warning!</Modal.Header>
            <Modal.Body>
                {/* <p>Are you sure you want to delete all selected <strong>{checkedVal.length}</strong> manuscript/s? Deleting it will permanently remove the manuscript and all associated files</p> */}
                <p>Are you sure you want to delete all selected manuscripts? This will permanently remove the manuscript and all associated files.</p>
                <button className="btnOutline w-100" onClick={()=> setShowDeleteAll(false)}>Cancel</button>
                {isDeleteAllLoading?<FieldLoader isLoading={isDeleteAllLoading} className="w-100 mt-2 btn-danger" /> :<button className="w-100 mt-2 btn-danger" onClick={(e)=>handleDelete(e)}>Delete selected manuscript/s <FontAwesomeIcon icon={faTrashCan} /></button>}
            </Modal.Body>

		</Modal>
    </div>
    )
}

export default ManuscriptsContent;