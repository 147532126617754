import React from "react";
import { Card, Col, Modal, ProgressBar, Row, Spinner } from "react-bootstrap";
import AxiosService from "../../utils/AxiosService";
import { useEffect } from "react";
import { useState } from "react";
import styles from "./PaymentSuccess.module.scss"
import { toast } from "react-toastify";
import { useHistory} from 'react-router-dom';
import { FieldLoader } from "../../components/Loader/CommonLoader";
import { dataDisplayRequest } from "../../utils/auth_service/AuthServiceHelper";
import JournalInfo from "../../components/Journals/JournalInfo";
import countryToCurrency from 'country-to-currency'
import classNames from "classnames";
import TooltipButton from "../../components/TooltipButton/TooltipButton";
import { Event } from "../../tracking";

const PaymentPage = ({userPaymentProfile, setUserPaymentProfile, creditPurchased, setCreditPurchased}) => {
    const queryParams = new URLSearchParams(window.location.search);
    const apiService = new AxiosService()
    
    const journalId = queryParams.get("journalId") ? queryParams.get("journalId") : false;
    const journalTitle = queryParams.get("journalTitle") ? queryParams.get("journalTitle") : false;
    const manuscriptId = queryParams.get("manuscriptId") ? queryParams.get("manuscriptId") : false;
    const clone = queryParams.get("clone") ? queryParams.get("clone") : false;
    const history = useHistory()

    const [loader, setLoader] = useState({paymentDetailsLoader: false, createManuscriptLoader: false, journalDetailLoader: false})
    const [modalControl, setModalControl] = useState({createManuscriptModal: false, forceManuscriptModal: false, paymentLinkModal: false})
    const [paymentMethod, setPaymentMethod] = useState("")
    const [manuscriptDetails, setManuscriptDetails] = useState({})
    const [journalDetails, setJournalDetails] = useState({})
    const [promoCode, setPromoCode] = useState("")
    const [codeVerified, setCodeVerified] = useState({expired: "", valid: "", utilized: "", loading: false})
    const [bulkOrder, setBulkOrder] = useState("perFormatGeneric")
    const [currencyType, setCurrencyType] = useState("GBP")
    const [applyClicked, setApplyClicked] = useState(true)
    const [payableAmount, setPayableAmount] = useState({})
    const [templateType, setTemplateType] = useState(queryParams.get("templateType") ? queryParams.get("templateType") : "Generic")
    const [codeConfirmationModal, setCodeConfirmationModal] = useState({visible: false, loading: false})
    const currencySymbols = {
        'USD': '$', // US Dollar
        'EUR': '€', // Euro
        'CRC': '₡', // Costa Rican Colón
        'GBP': '£', // British Pound Sterling
        'ILS': '₪', // Israeli New Sheqel
        'INR': '₹', // Indian Rupee
        'JPY': '¥', // Japanese Yen
        'KRW': '₩', // South Korean Won
        'NGN': '₦', // Nigerian Naira
        'PHP': '₱', // Philippine Peso
        'PLN': 'zł', // Polish Zloty
        'PYG': '₲', // Paraguayan Guarani
        'THB': '฿', // Thai Baht
        'UAH': '₴', // Ukrainian Hryvnia
        'VND': '₫', // Vietnamese Dong
    };
    

    const getJournalDetails = () => {
        setLoader({...loader, journalDetailLoader: true})
        const config = {
            method: 'get',
            url: '/api/v1/serch-service/getDetail/' + journalId,
        }
        dataDisplayRequest(config).then((res) => {
            setJournalDetails(res.data)
            setLoader({...loader, journalDetailLoader: false})
        }, (err) => {
            setLoader({...loader, journalDetailLoader: false})
            toast.error(err?.response?.data?.message, {autoClose: 3000})
        }).catch((err) => {
            setLoader({...loader, journalDetailLoader: false})
            toast.error(err?.response?.data?.message, {autoClose: 3000})
        })
    }

    const getCreditAmount = (orderType) => {
        switch(orderType) {
            case 'perFormatGeneric':
                return 1
            case 'perFormatSpecific':
                return 2
            case 'bulk6':
                return 6
            case 'bulk12':
                return 12
            case 'bulk24':
                return 24
            default:
                return orderType
        }
    }

    const handlePayment = (manuscriptId) => {
        if (payableAmount.priceId || paymentMethod === "couponCode") {
            if (paymentMethod === "perFormatAmount" ) {
                setLoader({...loader, paymentDetailsLoader: true})
                const body = {
                    journalId: journalId,
                    purchaseType: "perFormat",
                    templateType: templateType ? templateType : "",
                    priceId: payableAmount.priceId
                }
                apiService.getStripeUrl(body).then((response) => {
                    setLoader({...loader, createManuscriptLoader: false})
                    setModalControl({...modalControl, createManuscriptModal: false, forceManuscriptModal: false})
                    window.location.assign(response.sessionURL)
                }, (error) => {
                    toast.error(error?.response?.data?.message, {autoClose: 3000})
                    setLoader({...loader, createManuscriptLoader: false})
                    setPaymentMethod("")
                    setLoader({...loader, paymentDetailsLoader: false})
                }).catch((error) => {
                    toast.error(error?.response?.data?.message, {autoClose: 3000})
                    setLoader({...loader, createManuscriptLoader: false})
                    setModalControl({...modalControl, createManuscriptModal: false, forceManuscriptModal: false})
                    setPaymentMethod("")
                    setLoader({...loader, paymentDetailsLoader: false})
                })
            } else if (paymentMethod === "couponCode") {
                const body = {
                    journalId: journalId,
                    templateType: templateType ? templateType : "Generic",
                    purchaseType: "couponCode",
                    couponCode: promoCode
                }
                apiService.purchaseCredit(body).then((response) => {
                    toast.success(response.message, {autoClose: 3000})
                    setLoader({...loader, createManuscriptLoader: false})
                    setModalControl({...modalControl, createManuscriptModal: false, forceManuscriptModal: false})
                    Event("Credit Purchased", `Promo Code - ${promoCode}`, 'Purchase credit clicked')
                    setCreditPurchased(creditPurchased+1)
                    setPaymentMethod("")
                    setPromoCode("")
                    setCodeVerified({expired: "", valid: "", utilized: "", loading: false})
                    if(journalId) {
                        setModalControl({...modalControl, createManuscriptModal: true})
                    }
                    setLoader({...loader, paymentDetailsLoader: false})
                    setCodeConfirmationModal({...codeConfirmationModal, visible: false, loading: false})
                }, (error) => {
                    setLoader({...loader, createManuscriptLoader: false})
                    setModalControl({...modalControl, createManuscriptModal: false, forceManuscriptModal: false})
                    toast.error(error?.response?.data?.message, {autoClose: 3000})
                    setLoader({...loader, paymentDetailsLoader: false})
                }).catch((error) => {
                    setLoader({...loader, createManuscriptLoader: false})
                    setModalControl({...modalControl, createManuscriptModal: false, forceManuscriptModal: false})
                    toast.error(error?.response?.data?.message, {autoClose: 3000})
                    setLoader({...loader, paymentDetailsLoader: false})
                })
            } else if (paymentMethod === "bulkOrder") {
                setLoader({...loader, paymentDetailsLoader: true})
                const body = {
                    journalId: journalId,
                    purchaseType: bulkOrder,
                    templateType: templateType ? templateType : "",
                    priceId: payableAmount.priceId,
                    manuscriptId: manuscriptId,
                    clone: clone
                }
                apiService.getStripeUrl(body).then((response) => {
                    setLoader({...loader, createManuscriptLoader: false})
                    setModalControl({...modalControl, createManuscriptModal: false, forceManuscriptModal: false})
                    Event("Credit Purchased", `Purchase Credit - ${getCreditAmount(bulkOrder)}`, 'Purchase credit clicked')
                    window.location.assign(response.sessionURL)
                }, (error) => {
                    toast.error(error?.response?.data?.message, {autoClose: 3000})
                    setLoader({...loader, createManuscriptLoader: false})
                    setLoader({...loader, paymentDetailsLoader: false})
                    setPaymentMethod("")
                }).catch((error) => {
                    toast.error(error?.response?.data?.message, {autoClose: 3000})
                    setLoader({...loader, createManuscriptLoader: false})
                    setModalControl({...modalControl, createManuscriptModal: false, forceManuscriptModal: false})
                    setLoader({...loader, paymentDetailsLoader: false})
                    setPaymentMethod("")
                })
            }
        } else {
            toast.warning("Prices are not available. Refresh the page and try again or try changing currency!", {autoClose: 3000})
        }
    }

    const createManuscript = (force) => {
        setLoader({...loader, createManuscriptLoader: true})
        if (clone) {
            const data = {
                id: manuscriptId,
                journalId: journalId,
                templateType: templateType
            }
            apiService.cloneManuscript(data).then((res) => {
                toast.success("Created successfully", {autoClose: 3000}) 
            }, (err) => {
                setLoader({...loader, createManuscriptLoader: false})
                if(err.response.status === 409) {
                    setManuscriptDetails(err.response.data)
                    setModalControl({...modalControl, forceManuscriptModal: true})
                } else {
                    toast.error(err?.response?.data?.message, {autoClose: 3000})
                }
            }).catch((err) => {
                setLoader({...loader, createManuscriptLoader: false})
                if(err.response.status === 409) {
                    setManuscriptDetails(err.response.data)
                    setModalControl({...modalControl, forceManuscriptModal: true})
                } else {
                    toast.error(err?.response?.data?.message, {autoClose: 3000})
                }
            })
        } else {
            const body = {
                "journalId": journalId,
                "force": force,
                "templateType": templateType
            }
            apiService.createManuscript(body).then((res) => {
                toast.success("Created successfully", {autoClose: 3000})
                setTimeout(() => {
                    history.push(`/manuscript-submission/${res.manuscriptId}`)
                }, 3000)
            }, (err) => {
                setLoader({...loader, createManuscriptLoader: false})
                if(err.response.status === 409) {
                    setManuscriptDetails(err.response.data)
                    setModalControl({...modalControl, forceManuscriptModal: true})
                } else {
                    toast.error(err?.response?.data?.message, {autoClose: 3000})
                }
            }).catch((err) => {
                setLoader({...loader, createManuscriptLoader: false})
                if(err.response.status === 409) {
                    setManuscriptDetails(err.response.data)
                    setModalControl({...modalControl, forceManuscriptModal: true})
                } else {
                    toast.error(err?.response?.data?.message, {autoClose: 3000})
                }
            })
        }
    }

    const continueClickHandle = () => {
        // if (!applyClicked) {
        //     if(promoCode.length > 0) {
        //         toast.error('Please click "Apply" before proceeding further to use the promo code.', {autoClose: 3000})
        //     } else {
        //         toast.error('Please enter valid promo code and click "Apply" before proceeding further.', {autoClose: 3000})
        //     }
        // } else {
            if(paymentMethod.length < 1 || paymentMethod === undefined || paymentMethod === "" || paymentMethod === null) {
                toast.warning("You have not selected a payment method. Please select a method of payment.", {autoClose: 3000})
            }
            if (paymentMethod === "perFormatAmount") {
                handlePayment(manuscriptId)
            }
            if (paymentMethod === "couponCode") {
                if(codeVerified.expired !== "no") {
                    toast.warning("Entered code is expired.", {autoClose: 3000})
                    return
                }
                if(codeVerified.valid !== "yes") {
                    toast.error("Entered code is not valid.", {autoClose: 3000})
                    return
                }
                if(codeVerified.valid === "yes" && codeVerified.utilized === "yes") {
                    toast.warning("Entered code is already used once.", {autoClose: 3000})
                    return
                }
                if(codeVerified.expired === "no" && codeVerified.valid === "yes" && codeVerified.utilized === "no") {
                    handlePayment(manuscriptId)
                } else {
                    toast.error("Error in verifying promo code.", {autoClose: 3000})
                }
            }
            if (paymentMethod === "bulkOrder") {
                handlePayment(manuscriptId)
            }
        // }

    }

    const applyPromo = (e) => {
        e.preventDefault()
        if(promoCode.replace(/^\s*$/, '').length > 0) {
            const body = {
                couponCode: promoCode.trim(),
                templateType: templateType,
            }
            setCodeVerified({...codeVerified, loading: true})
            apiService.checkCoupon(body).then((res) => {
                setCodeVerified({...codeVerified, ...res, loading: false})
                setApplyClicked(true)
                if(res.expired === "no" && res.valid === "yes" && res.utilized === "no") {
                    setCodeConfirmationModal({...codeConfirmationModal, visible: true})
                }
            }).catch((err) => {
                setCodeVerified({...codeVerified, loading: false})
                setApplyClicked(true)
                toast.warning(err?.response?.data?.message, {autoClose: 3000})
            })
        } else {
            toast.warning("Empty promo codes are not allowed!", {autoClose: 3000})
        }
    }

    const getPrices = (paymentType, returnType) => {
        let priceObj = userPaymentProfile.priceDetails.filter(item => item.purchaseType === paymentType)
        let amount = priceObj[0] ? priceObj[0].prices.filter(item => item.currency === currencyType.toUpperCase()) : [{}]
        if(amount[0].amount) {
            if(returnType === "string") {
                return amount[0].amount
            } else if (returnType === "object") {
                return amount[0]
            }
        } else {
            if(returnType === "string") {
                return "N/A"
            } else if (returnType === "object") {
                return {amount: "N/A"}
            }
        }
    }

    useEffect(() => {
        if(journalId) {
            getJournalDetails()
        }
        if(navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((pos) => {
                apiService.getCountryName(pos.coords.latitude,pos.coords.longitude).then((res) => {
                    // toast.success("Country found: " + res.data.countryCode + ", Currency found: " + countryToCurrency[res.data.countryCode].toUpperCase())
                    if(countryToCurrency[res.data.countryCode].toUpperCase() === "GBP" || 
                        countryToCurrency[res.data.countryCode].toUpperCase() === "USD" || 
                        countryToCurrency[res.data.countryCode].toUpperCase() === "EUR"
                    ) {
                        setCurrencyType(countryToCurrency[res.data.countryCode].toUpperCase())
                    } else {
                        setCurrencyType("GBP")
                    }
                }).catch((err) => {
                    console.log("Error in getting country: ", err, err?.response)
                })
            }, (err) => {
                console.log("Error in navigator: ", err, err?.response)
            })
        } else {
            console.log("Location services failed")
        }
    },[])

    useEffect(() => {
        if(paymentMethod === "perFormatAmount") {
            setPayableAmount(getPrices("perFormat", "object"))
        } else if (paymentMethod === "bulkOrder") {
            setPayableAmount(getPrices(bulkOrder, "object"))
        }
    }, [paymentMethod, currencyType, bulkOrder])

    useEffect(() => {
        if(userPaymentProfile) {
            setPaymentMethod("bulkOrder")
        }
        if(templateType === "Specific") {
            setBulkOrder("perFormatSpecific")
        } else {
            setBulkOrder("perFormatGeneric")
        }
    }, [userPaymentProfile])

    return (
        <div>
            <Card className="mt-3">
                {journalId && 
                    <Card.Header>
                        <JournalInfo isloading={loader.journalDetailLoader} journalInfo={journalDetails} key={1} visible={false}/>
                    </Card.Header>
                }
                <Card.Body>
                    <Row>
                        <Col md={12}>
                            {userPaymentProfile?.credit !== undefined && 
                                <div className="d-flex flex-column justify-content-between">
                                    <Row className="d-flex">
                                        <Col lg={6} md={6} sm={12} className="justify-content-between mb-3">
                                            <fieldset>
                                                <legend>
                                                    <Col className="mb-4">
                                                        <h1 className={styles.h4FontSize}>Select your plan</h1>
                                                    </Col>
                                                </legend>
                                                <Col className="">
                                                    <TooltipButton noTrigger={true} tooltipMessage={paymentMethod !== "couponCode" ? "Please select this card to use the promo code" : null}>
                                                        <Col md={12} className="mb-3 p-0 m-0">
                                                            <div onClick={() => {setPaymentMethod("couponCode"); setApplyClicked(false)}} className={paymentMethod === "couponCode" ? classNames(styles.selectedCardBorder, "border rounded shadow d-flex flex-row align-items-start p-2 mb-2") : "border rounded shadow d-flex flex-row align-items-start p-2 mb-2"}>
                                                                <input className="mt-2 ml-2 d-none" type="radio" name="paymentMethod" value="couponCode" onChange={(e) => {
                                                                        if(e.target.checked) {
                                                                            setPaymentMethod(e.target.value)
                                                                            setApplyClicked(false)
                                                                        } else {
                                                                            setApplyClicked(true)
                                                                        }
                                                                    }}
                                                                    checked={paymentMethod === "couponCode"}
                                                                    aria-label="Use promo code"
                                                                />
                                                                <div className="d-flex flex-column ml-2 w-100">
                                                                    <h5 className="ml-2">Use promo code </h5>
                                                                    <span className="ml-2 mb-2 small">Please add your code here and click 'Apply' (case sensitive)</span>
                                                                    <div className="d-flex align-items-center row">
                                                                        <div className="d-flex flex-row w-100">
                                                                            <input 
                                                                                type="text" 
                                                                                value={promoCode} 
                                                                                onChange={(e) => {setPromoCode(e.target.value); setApplyClicked(false)}} 
                                                                                placeholder="Enter code here"
                                                                                className="form-control ml-2"
                                                                                aria-label="Enter code here"
                                                                            />
                                                                            <button 
                                                                                className="mx-3 btn-outline sm-12" 
                                                                                disabled = {paymentMethod !== "couponCode"}
                                                                                onClick={(e) => {applyPromo(e);}}
                                                                            > 
                                                                                {codeVerified.loading ? 
                                                                                    <Spinner  as="span" animation="grow" size="sm" role="status" aria-hidden="true"/> 
                                                                                : 
                                                                                    "Apply"
                                                                                } 
                                                                            </button>
                                                                        </div>
                                                                        {!codeVerified.loading ? 
                                                                            <div className="col-12 mt-2">
                                                                                {codeVerified.valid === "yes" && codeVerified.expired === "no" && codeVerified.utilized === "no" &&  
                                                                                    <span className="text-success text-nowrap">Code applied</span>
                                                                                }
                                                                                {codeVerified.valid === "no" && codeVerified.expired === "no" && codeVerified.utilized === "no" &&
                                                                                    <span className="text-danger text-nowrap">Incorrect code</span>
                                                                                }
                                                                                {codeVerified.expired === "yes" && codeVerified.valid === "no" && codeVerified.utilized === "no" &&
                                                                                    <span className="text-warning text-nowrap">Code expired</span>
                                                                                }
                                                                                {codeVerified.valid === "yes" && codeVerified.utilized === "yes" && codeVerified.expired === "no" && 
                                                                                    <span className="text-warning text-nowrap">Code already used</span>
                                                                                }
                                                                            </div>
                                                                        :
                                                                            <>
                                                                                {promoCode.length > 0 ? 
                                                                                    <div className="col-12 mt-2">
                                                                                        <Spinner  as="span" animation="grow" size="sm" role="status" aria-hidden="true"/>
                                                                                    </div>
                                                                                :
                                                                                    ""
                                                                                }
                                                                            </>
                                                                        }
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </TooltipButton>
                                                    <TooltipButton tooltipMessage={paymentMethod !== "bulkOrder" ? "Please select this card to purchase credits" : null}>
                                                    <Col md={12} className="mb-3 p-0 m-0">
                                                        <div onClick={() => {setPaymentMethod("bulkOrder"); setApplyClicked(true)}} className={paymentMethod === "bulkOrder" ? classNames(styles.selectedCardBorder, "border rounded shadow d-flex flex-row align-items-start p-2 mb-2") : "border rounded shadow d-flex flex-row align-items-start p-2 mb-2"}>
                                                            <input className="mt-2 ml-2 d-none" type="radio" name="paymentMethod" value="bulkOrder" onChange={(e) => {
                                                                    if(e.target.checked) {
                                                                        setPaymentMethod(e.target.value)
                                                                        setApplyClicked(true)
                                                                    }
                                                                }}
                                                                checked={paymentMethod === "bulkOrder"}
                                                                aria-label="Purchase credit"
                                                            />
                                                            <div className="d-flex flex-column ml-2 w-100 mb-2">
                                                                <h5 className="ml-2">Purchase credits</h5>
                                                                <span className="ml-2 mb-2 small">Select the number of credits you want to purchase</span>
                                                                <div className="d-flex flex-row justify-content-between">
                                                                    <div className="d-flex flex-row justify-content-between align-itemms-center w-50">
                                                                        <select className="form-control" aria-label="Select credit number" value={bulkOrder} onChange={(e) => {setBulkOrder(e.target.value)}}>
                                                                            <option value="perFormatGeneric">1 credit</option>
                                                                            <option value="perFormatSpecific">2 credits</option>
                                                                            <option value="bulk6">6 credits</option>
                                                                            <option value="bulk12">12 credits</option>
                                                                            <option value="bulk24">24 credits</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        {loader.paymentDetailsLoader ?
                                                                            <FieldLoader isLoading={loader.paymentDetailsLoader}/>
                                                                        :
                                                                            <button 
                                                                                className="shadow"
                                                                                disabled={paymentMethod !== "bulkOrder"}  
                                                                                onClick={() => {
                                                                                    continueClickHandle()
                                                                                }}
                                                                            >
                                                                                Purchase Credits
                                                                            </button>
                                                                        }
                                                                    </div>
                                                                    {/* {!codeVerified.loading ? 
                                                                        <div className="col-12 mt-2">
                                                                            {codeVerified.valid === "yes" && codeVerified.expired === "no" && codeVerified.utilized === "no" &&  
                                                                                <span className="text-success text-nowrap">Code applied</span>
                                                                            }
                                                                            {codeVerified.valid === "no" &&
                                                                                <span className="text-danger text-nowrap">Incorrect code</span>
                                                                            }
                                                                            {codeVerified.expired === "yes" && 
                                                                                <span className="text-warning text-nowrap">Code expired</span>
                                                                            }
                                                                            {codeVerified.valid === "yes" && codeVerified.utilized === "yes" && 
                                                                                <span className="text-warning text-nowrap">Code already used</span>
                                                                            }
                                                                        </div>
                                                                    :
                                                                        <>
                                                                            {promoCode.length > 0 ? 
                                                                                <div className="col-12 mt-2">
                                                                                    <Spinner  as="span" animation="grow" size="sm" role="status" aria-hidden="true"/>
                                                                                </div>
                                                                            :
                                                                                ""
                                                                            }
                                                                        </>
                                                                    } */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="border rounded d-flex flex-row align-items-start p-2 my-5">
                                                            <div className="d-flex flex-column ml-2 w-100 mb-2">
                                                                <h5 className="ml-2">Templates available:</h5>
                                                                <p className="ml-2 text-justify"><strong>Universal template</strong> - Our universal template will help you meet common requirements for submission from reference, figure and table citations to figure quality and formatting errors.</p>
                                                                <p className="ml-2 text-justify"><strong>Journal-specific templates</strong> - Follow the journal’s instructions to author guidelines. Currently available for The Bone & Joint Journal, Bone & Joint Research, Bone & Joint Open. More templates will be added soon.</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    </TooltipButton>
                                                </Col>
                                            </fieldset>
                                        </Col>

                                        <Col lg={6} md={6} sm={12}>
                                            <Col className="d-flex align-items-center mb-3">
                                                <h4 className="d-flex ml-0 pl-0 flex-nowrap col-lg-9 col-md-9 col-sm-12">Available Credits: {userPaymentProfile?.credit}</h4>
                                                <select aria-label="select currency" className="form-control col-lg-3 col-md-3 col-sm-12" value={currencyType.length ? currencyType : "GBP"} onChange={(e) => {setCurrencyType(e.target.value)}}>
                                                    {userPaymentProfile?.priceDetails[0]?.prices.map((item) => {
                                                        return (
                                                            <>
                                                                <option value={item.currency.toUpperCase()}>{item.currency} ({currencySymbols[item.currency.toUpperCase()]})</option>
                                                            </>
                                                        )
                                                    })}
                                                </select>
                                            </Col>
                                            <Col md={12} className="">
                                                <Col md={12} className="p-0 m-0">
                                                    <div className="border rounded align-items-start">
                                                        <div className="d-flex flex-row justify-content-between aling-items-center">
                                                            <h5 className="text-secondary mt-3 ml-3">Cost of credits</h5>
                                                            <div className="d-flex flex-column my-2 mx-3">
                                                                <span className="text-secondary font-weight-bold ml-auto mb-2">Universal template = 1 credit</span>
                                                                <span className="text-secondary font-weight-bold ml-auto mb-1">Journal-specific template = 2 credits</span>
                                                            </div>
                                                        </div>
                                                        <div className="d-none d-md-block">
                                                            <table class="table">
                                                                <tr>
                                                                    <th width="50%">Credits</th>
                                                                    {userPaymentProfile?.priceDetails[0]?.prices.map((item) => {
                                                                        if(item?.currency === currencyType) {
                                                                            return (
                                                                                <>
                                                                                    <th>{item.currency} ({currencySymbols[item.currency.toUpperCase()]}{item?.currency === "GBP" ? " exc. VAT" : ""})</th>
                                                                                </>
                                                                            )
                                                                        }
                                                                    })}
                                                                </tr>
                                                                {userPaymentProfile.priceDetails.map((priceDetail) => {
                                                                    return(
                                                                        <>
                                                                            { priceDetail.credit !== 2 ? 
                                                                                <tr>
                                                                                    <td>
                                                                                        {priceDetail.credit} <br/>
                                                                                        {priceDetail?.discount ? <span className="small text-secondary">({priceDetail.discount}% disc)</span> : ""}
                                                                                    </td>
                                                                                    {priceDetail.prices.map((price) => {
                                                                                        if(price?.currency === currencyType) {
                                                                                            return(
                                                                                                <>
                                                                                                    <td>
                                                                                                        {price.amount ? currencySymbols[price.currency.toUpperCase()]+price.amount : "N/A"} <br/>
                                                                                                        {price?.listPrice ? <span className="small text-secondary">(list price: {currencySymbols[price.currency.toUpperCase()]+price.listPrice})</span> : ""}
                                                                                                    </td>
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                    })}
                                                                                </tr>
                                                                            :
                                                                                <></>
                                                                            }
                                                                        </>
                                                                    )
                                                                })}
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="mt-2 d-sm-block d-md-none">
                                                        {userPaymentProfile?.priceDetails[0]?.prices.map((item) => {
                                                            if(item?.currency === currencyType) {
                                                                return (
                                                                    <>
                                                                        <div className="border rounded align-items-start my-2">
                                                                            <div className="d-flex flex-row justify-content-between aling-items-center my-2">
                                                                                {/* <h5 className="text-secondary ml-3">Currency</h5> */}
                                                                                <h5 className="text-secondary ml-3 mr-3">{item.currency} ({currencySymbols[item.currency.toUpperCase()]}{item.currency === "GBP" ? " exc. VAT" : ""})</h5>
                                                                            </div>
                                                                            <table className="table">
                                                                                {userPaymentProfile.priceDetails.map((priceDetail) => {
                                                                                    return (
                                                                                        <>
                                                                                            {priceDetail.credit !== 2 ? 
                                                                                                <tr>
                                                                                                    <td className="text-secondary">
                                                                                                        {priceDetail.credit} {priceDetail.credit > 1 ? "credits" : "credit"} <br/>
                                                                                                        {priceDetail?.discount ? <span className="small">({priceDetail.discount}% disc)</span> : ""}
                                                                                                    </td>
                                                                                                    {priceDetail.prices.map((price) => {
                                                                                                        if(price?.currency === item.currency) {
                                                                                                            return (
                                                                                                                <td className="text-secondary">
                                                                                                                    {price.amount ? currencySymbols[price.currency.toUpperCase()]+price.amount : "N/A"} <br/>
                                                                                                                    {price?.listPrice ? <span className="small text-secondary">(list price: {currencySymbols[price.currency.toUpperCase()]+price.listPrice})</span> : ""} 
                                                                                                                </td>
                                                                                                            )
                                                                                                        }
                                                                                                    })}
                                                                                                </tr> 
                                                                                            : 
                                                                                                <></>
                                                                                            }
                                                                                        </>
                                                                                    )        
                                                                                })}
                                                                            </table>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                        })}
                                                    </div>
                                                </Col>
                                            </Col>
                                        </Col>
                                    </Row>
                                </div>
                            }
                            {userPaymentProfile?.credit === undefined && 
                                <>
                                    <ProgressBar animated now={100} variant="secondary" className="mb-2"/>
                                    <ProgressBar animated now={100} variant="secondary" className="mb-2"/>
                                    <ProgressBar animated now={100} variant="secondary" className="mb-2"/>
                                    <ProgressBar animated now={100} variant="secondary" className="mb-2"/>
                                    <ProgressBar animated now={100} variant="secondary"/>
                                </>
                            }
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            {/* Create Confirmation Modal */}
            <Modal show={modalControl.createManuscriptModal} onHide={() => {
                if (!loader.createManuscriptLoader) {
                    setPaymentMethod("")
                    setModalControl({...modalControl, createManuscriptModal: false})
                }
            }}>
                <Modal.Header closeButton className='border-bottom-0'>
                    <Modal.Title className='h5'>Format manuscript?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        You will be formatting a new manuscript for <strong>{journalDetails.journal_title}</strong>. Are you sure you want to proceed?
                    </p>
                    <section>
                        { loader.createManuscriptLoader ? 
                            <FieldLoader className="w-100 mb-2" isLoading={loader.createManuscriptLoader}/>
                        :
                            <button className="w-100 mb-2" onClick={() => createManuscript(false)}>Start now</button>
                        }
                        <button className={'w-100 mb-2 customButton'} disabled={loader.createManuscriptLoader} onClick={()=> {
                                setPaymentMethod("")
                                setModalControl({...modalControl, createManuscriptModal: false})
                                history.push("/select-journal?sort=journal_title_asc")
                            }}
                        >
                            Select a different journal
                        </button>
                    </section>
                </Modal.Body>
            </Modal>

            {/* Create Forced Confirmation Modal */}
            <Modal show={modalControl.forceManuscriptModal} onHide={() => {
                setPaymentMethod("")
                setModalControl({...modalControl, forceManuscriptModal: false, createManuscriptModal: false})
            }}>
                <Modal.Header closeButton className='border-bottom-0'>
                    <Modal.Title className='h5'>Multiple drafts alert</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        You have previously created <strong>{manuscriptDetails.count}</strong> submission drafts for this journal. Would you like to create a new submission or continue working on a previous draft?
                    </p>
                    <section>
                        <button className={'w-100 mb-2 customButton'} onClick={()=> {
                                setPaymentMethod("")
                                setModalControl({...modalControl, createManuscriptModal: false, forceManuscriptModal: false})
                                history.push(`/my-dashboard?journalTitle=${encodeURIComponent(journalDetails.journal_title)}&status=Draft`)
                            }}
                        >
                            Continue working on a draft
                        </button>
                        { loader.createManuscriptLoader ? 
                            <FieldLoader className="w-100" isLoading={loader.createManuscriptLoader}/>
                        :
                            <button className="w-100" onClick={() => {createManuscript(true)}}>Create a new submission</button>
                        }   
                    </section>
                </Modal.Body>
            </Modal>

            <Modal show={codeConfirmationModal.visible} onHide={() => {
                setPaymentMethod("")
                setCodeConfirmationModal({...codeConfirmationModal, visible: false, loading: false})
            }}>
                <Modal.Header closeButton className='border-bottom-0'>
                    <Modal.Title className='h5'>Promo code confirmation alert</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        You have used the promo code <strong>{promoCode}</strong>. Would you like to continue with this promo code?
                    </p>
                    <section>
                        <button className={'w-100 mb-2 customButton'} onClick={() => {setCodeConfirmationModal({...codeConfirmationModal, visible: false, loading: false})}}>
                            No
                        </button>
                        { codeConfirmationModal.loading ? 
                            <FieldLoader className="w-100" isLoading={loader.createManuscriptLoader}/>
                        :
                            <button className="w-100" onClick={()=> {
                                setCodeConfirmationModal({...codeConfirmationModal, loading: true})
                                continueClickHandle()
                            }}>
                                Yes
                            </button>
                        }   
                    </section>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default PaymentPage