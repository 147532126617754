import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { PageView } from '../../tracking';
import './TermsNConditions.scss'

function TermsNConditions(){
    
    useEffect(() => {
        PageView();
    }, [])

    return(
        <Row className="mt-4 bg-white mx-0 tearmsNConditions rounded">
            <Col md={12} className="p-3">
                <h1 style={{fontSize: "1.25rem"}} className="font-weight-bold">Terms and Conditions</h1><hr />
                <div>
                    <p>These terms and conditions (“<strong>Terms</strong>”) apply to each of our websites: <a href='https://boneandjoint.org.uk/' target='_blank'>https://boneandjoint.org.uk/</a>, <a href='https://www.orthomedia.org.uk' target='_blank'>www.orthomedia.org.uk</a>, <a href='https://www.orthopublish.org.uk' target='_blank'>www.orthopublish.org.uk</a> and <a href='https://www.orthosearch.org.uk/Home' target='_blank'>www.orthosearch.org.uk/Home</a> (the “<strong>Sites</strong>”) and govern your access to and use of our online platforms including OrthoMedia, OrthoSearch and OrthoPublish (collectively, the “<strong>Platforms</strong>”) which are provided by us as part of the services (the “<strong>Services</strong>”).</p>
                    <p>By using the Sites and the Services, you agree to be bound by these Terms. If you do not agree with these Terms, please do not use the Sites or the Services. In these Terms, we explain what you can expect from our Sites and Services and what we will expect from you in relation to your use of our Sites and Services. We explain what you can and cannot do when using the Sites and the Services and what our obligations and liabilities are to you. Please take some time to read the following terms. By using any of our Sites and our Services you agree to be bound by these Terms. Please also see our Privacy Notice <a href='https://boneandjoint.org.uk/privacy' target='_blank'>https://boneandjoint.org.uk/privacy</a> which sets out details of how we will process your personal information. The Sites are owned by <strong>The British Editorial Society of Bone & Joint Surgery</strong> (<strong>us</strong>, <strong>our</strong>, or <strong>we</strong>). Our registered address is 22 Buckingham Street, London, WC2N 6ET. Our company registration number is 522061 and we are a company registered in England and Wales. The term <strong>you</strong> or <strong>your</strong> refers to the user or viewer or contributor to our Sites. Our VAT number is GB 23242338 01.</p>
                    <p>The platform provider for OrthoMedia is Cadmore Media Limited with their registered address being Fernhill Church Lane, Drayton, Abingdon, United Kingdom, OX14 4JS. Their company registration number is 11349346 and they are a company registered in England and Wales. You can find more information on them here <a href='https://cadmore.media/' target='_blank'>https://cadmore.media/</a>.</p>
                    <p>The platform provider for OrthoSearch and OrthoPublish are Molecular Connections Pvt. Ltd. a company registered in India.  You can find more information on them here <a href='https://molecularconnections.com/' target='_blank'>https://molecularconnections.com/</a>. Molecular Connections solely own the third party technology that OrthoPublish relies on which we have been granted the rights to use.</p>
                    <p>We may revise these Terms at any time. Any changes made to these Terms will be applicable from the date stated at the end of these Terms, please therefore ensure you read these Terms each time you access the applicable Sites. If you do not to accept any new Terms we make available then you should not use the applicable Sites.</p>
                    <h6>1.	Operation of the site</h6>
                    <section className='pl-3'>
                        <p>1.1	We make no guarantee or warranty that the operation of the Sites, the Platforms or the Services will be uninterrupted or free from errors, that defects will be corrected, and that the Sites, the Platforms and the server that makes them available is free from viruses. While we make every effort to keep our Sites online 24 hours a day, 7 days a week, due to technical failures, routine maintenance or circumstances beyond our control, availability may be limited and/or information lost. We shall not be liable for lost information, damage, or non-availability of our Sites, Platforms or the Services.</p>
                        <p>1.2	Your use of our Sites, Platforms or the Services is entirely at your own risk.</p>
                    </section>
                    <h6>2.	Access to the Services</h6>
                    <section className='pl-3'>
                        <p>2.1	Subject to you paying any applicable fees (the “<strong>Fees</strong>”) to us in accordance with clause 8 and complying with the restrictions set out in these Terms we grant to you a non-exclusive, non-transferable right to permit you (the “<strong>Authorised User</strong>”) access to the Platforms and use of the Services.</p>
                        {/* <p>2.2 You are solely responsible for ensuring the confidentiality of your Username and Password and for all activity that occurs under your Username and Password. We will be entitled to assume that anyone logging into any account of yours using your log in details is you. You must notify us immediately if there is any breach of security including unauthorised use of your account. We will not be held liable for any claim relating to the use or misuse of your Username and Password.</p> */}
                    </section>
                    <h6>3.	Authorised Use of the Services</h6>
                    <section className='pl-3'>
                        <p>3.1	You shall create a secure password that is personal to you in order to facilitate your access to the Sites, Platforms and Services. The username and password is confidential and must not be disclosed to any third party without our prior written consent. It is strictly prohibited for another user to enter any parts of the Sites, Platforms and Services with your username and password. Sharing of your username and password will result in your immediate suspension from the Sites.</p>
                        <p>3.2	You are solely responsible for ensuring the confidentiality of your username and password and for all activity that occurs under your username and password. We will be entitled to assume that anyone logging into any account of yours using your log in details is you. You must notify us immediately if there is any breach of security including unauthorised use of your account. We will not be held liable for any claim relating to the use or misuse of your username and password.</p>
                        <p>3.3	You shall not access, store, distribute or transmit any viruses, or any material during the course of your use of the Services that:</p>
                        <section className='pl-3'>
                            <p>3.3.1	is unlawful, harmful, threatening, defamatory, obscene, infringing, harassing or racially or ethnically offensive;</p>
                            <p>3.3.2	facilitates illegal activity;</p>
                            <p>3.3.3	depicts sexually explicit images;</p>
                            <p>3.3.4	promotes unlawful violence;</p>
                            <p>3.3.5	is discriminatory based on race, gender, colour, religious belief, sexual orientation, disability; or</p>
                            <p>3.3.6	is otherwise illegal or causes damage or injury to any person or property;</p>
                            <p>and we reserve the right, without liability or prejudice to our other rights to you, to disable your access to our site.</p>
                        </section>
                        <p>3.4	You shall not:</p>
                        <section className='pl-3'>
                            <p>3.4.1	except as may be allowed by any law which cannot be excluded and except to the extent expressly permitted under these Terms:</p>
                            <section className='pl-3'>
                                <p>(a)	attempt to copy, modify, duplicate, create derivative works from, frame, mirror, republish, display, transmit, or distribute all or any portion of the Site, Platforms or the Services (as applicable) in any form or media or by any means; or</p>
                                <p>(b)	attempt to de-compile, reverse compile, disassemble, reverse engineer or otherwise reduce to human-perceivable form all or any part of the Platform;</p>
                            </section>
                                <p>3.4.2	access all or any part of the Services and the Platforms in order to build a product or service which competes with the Services and/or the Platforms;</p>
                                <p>3.4.3	use the Services and/or Platform to provide services to third parties;</p>
                                <p>3.4.4	license, sell, rent, lease, transfer, assign, distribute, display, disclose, or otherwise commercially exploit, or otherwise make the Platforms and/or Services available to any third party except the Authorised Users;</p>
                                <p>3.4.5	attempt to obtain, or assist third parties in obtaining, access to the Services and/or Platform, other than as provided under here; or</p>
                                <p>3.4.6	introduce or permit the introduction of, any virus into our network and information systems.</p>
                        </section>
                    </section>
                    <h6>4.	Submitting Material for Publication </h6>
                    <section className='pl-3'>
                        <p>4.1	Publication of any material you submit to us will be at our discretion. We reserve the right to refuse publication and where applicable, make changes to your submission prior to publication and  maintain editorial control over the content of the material you submit.</p>
                        <p>4.2	You must not submit for publication material which is illegal, defamatory, abusive, pornographic, untrue, inaccurate, incorrect, fraudulent, discriminatory, inflammatory, racist or threatening, which contains errors or viruses, or which is otherwise actionable by law. We reserve the right to revoke your privilege to submit content to us at any time.</p>
                        <p>4.3	You further agree that in any activity undertaken following use of and related to your use of the Sites you will not in any way conduct yourself in a manner which is unlawful or which gives rise to civil or criminal liability or which is defamatory, libellous, untrue, discriminatory, obscene, inflammatory or racist.</p>
                        <p>4.4	It is your responsibility to ensure that all content you submit to us (including but not limited to all images and tables) have appropriate copyright releases and permissions for use and you warrant that you hold all necessary rights, licences and permissions. </p>
                    </section>
                    <h6>5.	Intellectual Property Rights</h6>
                    <section className='pl-3'>
                        <p>5.1	All copyright, trade mark rights, database rights, patent rights and any other intellectual property rights on our Sites, Platforms, Services and all content, software and other materials and information including the organisation and layout of the Sites and the underlying software code published on or relating to the Sites and Platforms is owned by us or our licensors.</p>
                        <p>5.2	The content, software, materials and information contained in or otherwise made available through the Sites, Platforms and Services and any information provided by us is for your personal use only and may not be used or distributed for commercial purposes without our written permission. You may only make a copy of this content for your personal non-commercial use provided that you retain all copyright and other proprietary notices contained in the materials and you agree not to modify, reproduce, republish, upload, post, distribute or otherwise transmit or use the content provided by us in any way without our prior written consent. You may not otherwise reproduce, copy, modify, alter, publish, broadcast, publish, distribute, communicate, sell or transfer any content, software, material or other information on the Sites or the underlying software code whether in whole or in part without our prior written permission.</p>
                        <p>5.3	The logo for The Bone & Joint  Journal is a registered trade mark of The British Editorial Society of Bone & Joint Surgery and other trade marks, logos and service marks displayed the Sites are trade marks of The British Editorial Society of Bone & Joint Surgery and may not be used without our written permission.</p>
                    </section>
                    <h6>6.	Our Obligations and Liability</h6>
                    <section className='pl-3'>
                        <p>6.1	We will undertake to ensure the Services will be performed with reasonable care and skill. However, this will not apply where you use the Services contrary to our instructions, or modify or alter the Services by any party other than us or our staff.</p>
                        <p>6.2	We try to make sure the content and information on the Sites is as accurate as possible. However we cannot guarantee that any content or materials provided on or via our Sites (including the content of any articles) is accurate or complete and publication by us does not mean that we endorse or recommend any opinions, specific tests, products, drug regimes or procedures that are described therein. Neither we, nor our officers, agents or employees shall be liable for any death or personal injury or other loss or damage arising out of or in connection with the Sites (including its contents) except where caused by our negligence.</p>
                        <p>6.3	Everything on the Sites, Platforms and Services is provided to you “as is” and “as available” without warranty of any kind, either express or implied, including, but not limited to, the implied warranties of satisfactory quality, fitness for a particular purpose or non-infringement. The information on the Sites is provided with the understanding that the information does not constitute any form of advice or recommendation by the Society, is not a substitute for professional medical care or advice and should be used only by qualified practitioners, trainees and medical experts as an aid in understanding current medical knowledge. A qualified physician should always be consulted in relation to any health problem or medical condition and reliance on any information provided on the Sites is entirely at your own risk.</p>
                        <p>6.4	If you find any inappropriate or inaccurate information on the Sites, please notify us immediately.</p>
                        <p>6.5	We are not responsible for and shall not be liable for any damage to or viruses that may infect your computer equipment or other property on account of your access to, use of, or browsing or your downloading of any materials or information from the Sites, Platforms or use of the Services. We will also not be responsible for any delays, delivery failure or any loss or damage from the transfer of communication such as the internet and you acknowledge the Services may occasionally be subject to limitations or delays due to the nature of communication.</p>
                        <p>6.6	We reserve the right not to ship, honour or complete orders where we decide they are incorrect for example where the price of an article or other product or service has been incorrectly stated on the Sites. In such cases we will contact you.</p>
                        <p>6.7	We reserve the right to monitor the use of the Sites in accordance with any applicable laws in order to (i) maintain the Sites, and (ii) to protect the rights of other users of the Sites.</p>
                        <p>6.8	We will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with: (i) use of, or inability to use, any of the Sites, Platforms, Services or any content; or (ii) use of or reliance on any content.</p>
                        <p>6.9	We will not be liable to you for: (i) loss of profits, sales, business, or revenue; (ii) business interruption; (iii) loss of anticipated savings; (iv) loss of information or data; (v) loss of business opportunity; (vi) loss of goodwill or reputation; or (vii) any indirect or consequential loss or damage.</p>
                        <p>6.10	Nothing in these Terms excludes or limits our liability for any liability that cannot be excluded, disclaimed or limited by applicable law.</p>
                        <p>6.11	Subject to the above, our total aggregate liability in contract (including in respect of the indemnity at Clause 12.2), tort (including negligence or breach of statutory duty), misrepresentation, restitution or otherwise, arising in connection with the performance or contemplated performance of these Terms shall be limited to the higher of: (a) the total Fees paid by you to us during the 12 months immediately preceding the date on which the claim arose; or (b) £100.</p>
                    </section>
                    <h6>7.	Your Obligations to Us</h6>
                    <section className='pl-3'>
                        <p>7.1	You agree that you will only use our Sites, Platforms and Services in accordance with these Terms and any additional terms and conditions which we may communicate from time to time and comply all applicable laws and regulations in respect of your use of the Services under these Terms.</p>
                        <p>7.2	You agree not to do anything or cause or permit anything to be done that may infringe, damage or endanger any of our intellectual property rights or any intellectual property rights of a third party or do anything that is otherwise unlawful.</p>
                        <p>7.3	You confirm that all registration details provided by you are complete and accurate and that you will not permit other people to use your email account or subscription to contact us.</p>
                        <p>7.4	The Sites, Platforms and Services are not intended for children. We do not knowingly collect or maintain the personal information of children under the age of 18. If you are under the age of 18, please do not access the Sites at any time or in any manner. We will take appropriate steps to delete the personal information of persons under the age of 18.</p>
                        <p>7.5	If you choose to purchase any materials from us, you agree to be responsible for all charges resulting from such purchase and you are responsible for all charges related to your access to the Sites and any transactions which you may enter into on our Sites.</p>
                        <p>7.6	You agree not to publish, post, disseminate, distribute or otherwise transmit any defamatory, offensive, infringing, obscene, indecent or otherwise unlawful or objectionable material or information through our Sites nor use the Sites for any chain letters, junk mail, 'spamming' material or any other form of bulk communication or otherwise cause any liability for us.</p>
                        <p>7.7	Unless otherwise agreed between us, any communication or material transmitted by you to the Sites by any means or otherwise will be treated as non-confidential and non-proprietary. Anything you transmit or post may be used by us for any purpose and you agree to waive any moral rights which you may have. You also permit any other user of the Sites to access, display, view, store and reproduce such material for personal use.</p>
                        <p>7.8	If for any reason we are held responsible for loss or damage or other costs incurred by a third party as a result of any use or conduct by you on our Sites, Platforms or the Services or any breach of these Terms by you, you shall be responsible to us and will reimburse our costs, damages and expenses that are suffered or incurred by the third party and/or us.</p>
                        <p>7.9	In the event that we consider or determine, in our absolute discretion, that you have breached, violated or contravened these terms or have otherwise demonstrated inappropriate conduct in the use of our Sites, we reserve the absolute right to take such measures as we see fit including terminating, suspending or restricting your use of the Sites.</p>
                    </section>
                    <h6>8.	Charges</h6>
                    <section className='pl-3'>
                        <p>8.1	To the extent you are paying for any of the Services the following clauses apply:</p>
                        <section className='pl-3'>
                            <p>8.1.1	You shall pay the Fees to us for the Services in advance of receiving the Services using a debit or credit card.</p>
                            <p>8.1.2	Where you engage a sales agent, you or your sales agent will pay the Fees into our bank account in advance of receiving the Services.</p>
                            <p>8.1.3	We may issue invoices or proforma invoices to you upon your request.</p>
                            <p>8.1.4	All Fees shall be payable in pounds sterling, dollars or euros; are non-cancellable and non-refundable unless you cancel within 7 days or we are not able to fulfil the order; and are exclusive of value added tax, which shall be added to the Fees at the appropriate rate.</p>
                            <p>8.1.5	We will be entitled to increase the Fees every 12 months.</p>
                        </section>
                    </section>
                    <h6>9.	Confidentiality</h6>
                    <section className='pl-3'>
                        <p>9.1	We both may be given access to information that is proprietary or confidential (“<strong>Confidential Information</strong>”) from each other in order to perform our obligations under these Terms. Each party shall hold the other’s Confidential Information in confidence and not make the other’s Confidential Information available to any third party, or use the other’s Confidential Information for any purpose other than the implementation of these Terms.</p>
                        <p>9.2	Either party may disclose Confidential Information where required to be disclosed by law, by any government or other regulatory authority or by a court. To one party is legally permitted to do so, it should give the other party as much notice of the disclosure as possible.</p>
                        <p>9.3	Subject to the above, we both shall take all reasonable steps to ensure that where we have access to the other’s Confidential Information, it is not disclosed or distributed by its staff in violation of these Terms.</p>
                    </section>
                    <h6>10.	Links to Other Sites</h6>
                    <section className='pl-3'>
                        <p>10.1	We may provide links to other websites, resources, advertisements or sponsorships either directly or through our partners. We accept no responsibility for these links. We do not endorse the contents of these sites and shall in no way be responsible or liable for such websites or the content, products or services available from such sites. If you decide to access other websites from the Sites you do so at your own risk. Any dealings you have with third parties via the Sites are your sole responsibility.</p>
                        <p>10.2	You may only link to our Sites with our express written permission. We expressly reserve the right to withdraw our consent at any time to a link which we think is inappropriate.</p>
                    </section>
                    <h6>11.	Termination</h6>
                    <section className='pl-3'>
                        <p>Your access to the Sites or the Services will continue until it is terminated by us, or until you provide notice to us of your decision to terminate your usage. We may discontinue or change the Sites, Platforms or the Services including its availability to you, at any time without notice.</p>
                    </section>
                    <h6>12.	General Provisions</h6>
                    <section className='pl-3'>
                        <p>12.1	You hereby undertake to us to indemnify and hold us harmless and defend us at your own expense against all claims, liabilities, costs and losses whatsoever and howsoever incurred by us or our staff arising out of any claim made against us in any jurisdiction in the world for infringement of any intellectual property rights of any third party caused by your use of the Sites, Platforms and/or the Services.</p>
                        <p>12.2	These Terms shall be governed by and construed in accordance with the laws of England and Wales. You agree that the sole jurisdiction and venue for any actions that may arise under or in relation to this subject matter shall be the courts located in England.</p>
                        <p>12.3	In the event that any part of these Terms is held to be unenforceable, such part will at our option be construed as far as possible to reflect our intentions and the remainder of the provisions will remain in full force and effect.</p>
                    </section>
                    <h6>13.	Contacting us</h6>
                    <p>Any communication or information concerning these Terms, the Sites, the software or services should be sent in writing to:</p>
                    <section>
                        <h4>The British Editorial Society of Bone & Joint Surgery</h4>
                        <p>22 Buckingham Street <br /> London <br/>WC2N 6ET, UK</p>
                    </section>
                    <p>or by email to: <a href='mailto: info@boneandjoint.org.uk?subject=Query about the information&body=Your query here...'> info@boneandjoint.org.uk</a></p>
                    <p className='pt-3 mt-3'><strong>Last revised:  29 July 2024</strong></p>
                </div>
            </Col>
        </Row>
    )
}

export default TermsNConditions;