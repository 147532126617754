import { faMagnifyingGlass, faRotate, faSquarePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col, Form, FormControl, Row, Spinner, Tab, Tabs } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { FieldLoader } from "../../../components/Loader/CommonLoader";
import Pagination from "../../../components/PaginationComponent/Pagination";
import AxiosService from "../../../utils/AxiosService";
import  "./MyDashboardPage.scss"
import ManuscriptsContent from "./ManuscriptsContent/ManuscriptsContent";
import Filter from "../../../components/Filter/Filter";
import { toast } from "react-toastify";

const MyDashboardPage = () => {
		const search = useLocation().search;
		const journalTitle = new URLSearchParams(search).get("journalTitle") || "";
		const JournalStatus = new URLSearchParams(search).get("status") || "Completed";
		const [manuscripts, setManuscripts] = useState([]);
		const [filters, setFilter] = useState([])
		const [isLoading, setIsLoading] = useState(false);
		const [isLoader, setLoader] = useState({isSearch: false, isReset: false});
		const apiService = new AxiosService();
		const [status, setStatus] = useState(JournalStatus);
		const [sortBy, setSortBy] = useState("updateDate");
		const history = useHistory();
		const [appliedFilter, setAppliedFilter] = useState([]);
		const [isStatusChange, setIsStatusChange] = useState(false);
		const [startVal, setStartVal] = useState(0);
		const [didYouMeanList, setDidYouMeanList] = useState([]);
		const queryParams = new URLSearchParams(window.location.search);
		const current = queryParams.get('from') ? JSON.parse(queryParams.get('from')) : 1;
		const sortType = queryParams.get('sort') ? queryParams.get('sort') : "";
		const [checkedVal, setCheckedVal] = useState([]);
		const [isCheckedAll, setIsCheckedAll] = useState(false)
		const [isDeleteAllLoading, setIsDeleteAllLoading] = useState(false)
		const [errorMessage, setErrorMessage] = useState(false)
		const [resetSearch, setResetSearch] = useState(false)
		const [tabsCount, setTabsCount] = useState({darfts: false, completed: false, archived: false})
		const [refreshCount, setRefreshCount] = useState(0)

	
		const [formValue, setFormValue] = useState({
			manuscriptTitle: "",
			journalTitle: journalTitle
		})
		const [currentPage, setCurrentPage] = useState(current);
		const [totalRecords, setTotalRecords] = useState(0);
		const offset = 10;
		const [pageNo, setPageNo] = useState(current)


		const handleChange=(event)=> {
				const {name, value} = event.target;
				setFormValue({...formValue, [name]: value});
		}

		const handleSearch = async(e) => {
				e.preventDefault();
				setLoader({...isLoader, isSearch: true});
				await getManuscripts([]);
				// handleDidYouMean()
				history.push(`/my-dashboard?status=${status}&from=1`)
		}

		const handleSort = (e) => {
				setSortBy(e.target.value);
				history.push(`/my-dashboard?status=${status}&manuscriptTitle=${formValue.manuscriptTitle?.trim()}&journalTitle=${formValue.journalTitle?.trim()}&from=${current}&facets=${ encodeURIComponent(JSON.stringify(appliedFilter))}&sort=${e.target.value}`)
		}

		const handleReset = () => {
				setAppliedFilter([])
				setLoader({...isLoader, isReset: true});
				setFormValue({
						...formValue,
						manuscriptTitle: "",
						journalTitle: "",
				});
				setSortBy(" ");
				setStatus("Completed");
				setCurrentPage(1)
				setPageNo(1)
				// history.push(`/my-dashboard?status=${status}`)
				setResetSearch(true)
		}

		const getManuscripts = (filter) => {
				setIsLoading(true);
				const start = (current - 1) * offset;
				setStartVal(start);
				const body = {
						"manuscriptTitle": formValue.manuscriptTitle.trim(),
						"journalTitle": formValue.journalTitle.trim(),
						"status": status,
						"filters": filter
				}
				apiService.fetchManuscripts(
						sortBy? sortBy: "",
						sortBy ===" "?"": sortBy==="updateDate"?"desc":"asc",
						start,
						offset,
						body
				).then(res=> {
						setIsLoading(false);
						setLoader({...isLoader, isReset: false, isSearch: false});
						setManuscripts(res.result);
						setFilter(res.filter)
						setTotalRecords(res.total);
						setErrorMessage(false)
						setRefreshCount(refreshCount+1)
			            history.push(`/my-dashboard?status=${status}&from=${current}`)
				}).catch(err=> {
						setIsLoading(false);
						setLoader({...isLoader, isReset: false, isSearch: false});
						if(err.status = 500) {
							setErrorMessage("Something went wrong!")
						} else {
							setErrorMessage(false)
						}
						toast.error(err?.response?.data?.message, {autoClose: 3000})
				})
			setCheckedVal([])
			setIsCheckedAll(false)
		}

		const handleDidYouMeanSearch = (word)=> {
			setFormValue({...formValue, manuscriptTitle: word})
			getManuscripts([])
			handleDidYouMean()
		}

		const getFilters = (filter) => {
			const body = {
					"manuscriptTitle": formValue.manuscriptTitle.trim(),
					"journalTitle": formValue.journalTitle.trim(),
					"status": status,
					"filters": filter
			}
			apiService.fetchManuscripts(
					sortBy,
					"asc",
					startVal,
					offset,
					body
			).then(res=> {
				setFilter(res.filter)
			}).catch(err=> {
				toast.error(err?.response?.data?.message, {autoClose: 3000})
			})
	}

		const handleSelectJournal = () => {
			history.push("/select-journal?sort=journal_title_asc");
		}

		const handleCurrentPage = (page) =>{
			setIsCheckedAll(false)
			setCheckedVal([])
			setCurrentPage(page);
			setPageNo(page)
			history.push(`/my-dashboard?status=${status}&from=${page}`)
		}

		function handleGoToPagination() {
			let totalPage= Math.ceil(totalRecords / offset)
			if(pageNo>0 && pageNo<=totalPage){
				history.push(`/my-dashboard?status=${status}&from=${parseInt(pageNo)}`)
				setPageNo(parseInt(pageNo))
			}
			else {
				toast.error("Please enter valid page number!", {autoClose: 3000})
			}
		}

		const handleTabChange =(value) => {
			setIsCheckedAll(false)
			setCheckedVal([])
			setStatus(value);
			setPageNo(1)
			history.push(`/my-dashboard?status=${value}&from=1`)
		}

		function handleAppliedFilter(filter, resetFilter) {
			if(filter?.length || resetFilter) {
				getManuscripts(filter)
			}
		}

		function handleDidYouMean(manuscriptTitle) {
			apiService.getDidYouMean({"query": formValue.manuscriptTitle.trim()}).then(res=> {
				setDidYouMeanList(res);
			}).catch(err=> {
				toast.error(err?.response?.data?.message, {autoClose: 3000})
			})
		}

		const getManuscriptCount = () => {
			apiService.fetchManuscripts("updateDate", "desc", 0, 1,
				{
					"manuscriptTitle": formValue.manuscriptTitle.trim(),
					"journalTitle": formValue.journalTitle.trim(),
					"filters": appliedFilter[0],
					"status": "Completed"
				}
			).then((formated) => {
				apiService.fetchManuscripts("updateDate", "desc", 0, 1,
					{
						"manuscriptTitle": formValue.manuscriptTitle.trim(),
						"journalTitle": formValue.journalTitle.trim(),
						"status": "Draft"
					}
				).then((draft) => {
					apiService.fetchManuscripts("updateDate", "desc", 0, 1,
						{
							"manuscriptTitle": formValue.manuscriptTitle.trim(),
							"journalTitle": formValue.journalTitle.trim(),
							"status": "Archived"
						}
					).then((archived) => {
						setTabsCount({completed: formated.total, darfts: draft.total, archived: archived.total})
					})
				})
			})
		}

		useEffect(()=>{
			if(status ==="Completed"){
				getManuscripts(appliedFilter[0])
			}else{
				getManuscripts([])
			}
		},[status, current])

		useEffect(()=> {
			let totalPage= Math.ceil(totalRecords / offset)
			if(current !== 0 && totalPage !==0 && current >totalPage) {
				setPageNo(1)
				history.push(`/my-dashboard?status=${status}&from=1`)
			}
		}, [totalRecords])

		useEffect(() => {
			getManuscripts(appliedFilter[0])
		}, [sortType])

		useEffect(() => {
			if(resetSearch === true) {
				getManuscripts([])
				setResetSearch(false)
			}
		}, [resetSearch, formValue])

		useEffect(() => {
			getManuscriptCount()
		}, [appliedFilter, refreshCount])


		return (
		<div>
				<section className="bg-white my-3 p-3 rounded">
						<h1 className="h3FontSize">My Dashboard</h1>
						<p>Use the dashboard to manage, review and update your manuscripts</p>
						<Form onSubmit={(e)=> handleSearch(e)}>
								<Row>
										<Col md={6}>
												<label htmlFor="dashboard-manuscript-title">Manuscript title</label>
												<FormControl
												type="text"
												placeholder="Enter manuscript title"
												name="manuscriptTitle"
												value={formValue.manuscriptTitle}
												onChange={(e)=> handleChange(e)}
												id="dashboard-manuscript-title"
												/>
										</Col>
										<Col md={6}>
												<label htmlFor="dashboard-journal-name">Journal name</label>
												<FormControl
												type="text"
												placeholder="Enter journal name"
												name="journalTitle"
												value={formValue.journalTitle}
												onChange={(e)=> handleChange(e)}
												id="dashboard-journal-name"
												/>
										</Col>
								</Row>
								<section className="row searchArea mt-3 d-flex flex-row justify-content-between">
										<div className="d-flex flex-row col-md-6 col-sm-12 mt-2">
											{isLoader.isSearch ? 
											<FieldLoader isLoading={isLoader.isSearch} />
											:<button type="submit" className="px-5">Search <FontAwesomeIcon icon={faMagnifyingGlass} className="h6 mb-0 pb-0 ml-2"/></button>
											}
											{isLoader.isReset ? 
											<FieldLoader isLoading={isLoader.isReset} className="btnReset" />
											:<button className="btnReset" onClick={handleReset}>Reset search</button>}
										</div>
										<div className="col-md-6 col-sm-12 mt-2 justify-content-end d-flex">
											<button className="btn-outline" onClick={() => {history.push(`/profile?tab=credits`)}}>View/Purchase Credits</button>
										</div>
								</section>
						</Form>
				</section>
					<div>
						<Row>
							<Col md={3}></Col>
							<Col md={9} className="px-0 resultDetailsSection">
							{/* {didYouMeanList.length >0 && <Col md={12} className="mt-2">
								<div>
									<span className='h6 text-muted'>Did you mean:</span> {didYouMeanList.map(word=> <span value={word} className='mx-2 linkText' onClick={(e)=> handleDidYouMeanSearch(word)}>{word}</span>)}
								</div>
								<hr />
							</Col>} */}
							<section className="row d-flex justify-content-between px-3">
								<div className="col-md-6 col-sm-12"><p className="h5">Showing {totalRecords} results</p></div>
								<div className="col-md-6 col-sm-12">
									<div className="d-flex justify-content-end">
									<label htmlFor="dashboardSortBy" className="h6 my-1 mr-2">Sort by:</label>
									<select id="dashboardSortBy" className="form-control mb-2 form-control-sm w-50" value={sortBy} onChange={(e)=> handleSort(e)} >
										<option value=" ">Please select</option>
										<option value="updateDate">Most recent</option>
										<option value="manuscriptTitle">Alphabetical by title</option>
										<option value="journalTitle">Alphabetical by journal</option>
									</select>
									</div>
								</div>
							</section>
							</Col>
							</Row>
						<Row>
							<Col md={3} className="filterSection mb-3">
								<Filter
								isDeleteAllLoading={isDeleteAllLoading}
								isLoading={isLoading} 
								isRefreshed={handleReset} 
								getAppliedFilter={handleAppliedFilter}
								getFilters={getFilters}
								filters={filters}
								filterType="STATUS" 
								appliedFilter={appliedFilter[0]}
								setAppliedFilter={setAppliedFilter}
								status={status}
								isStatusChange={isStatusChange}
								loader={isLoader}
								sortValue={sortBy}
								/>
							</Col>
							<Col md={9} className="tabSection">
							<section className="bg-white p-3 rounded position-relative">
							<div className="d-flex flex-column">
								<button className="btnPosition" onClick={handleSelectJournal}>Format new manuscript <FontAwesomeIcon icon={faSquarePlus} className="ml-2" /></button>
							</div>
							<Tabs
							 activeKey={status}
							 onSelect={(k)=>handleTabChange(k)}
							 className="navTabs"
							>
								<Tab eventKey="Completed" title={"Formatted manuscripts " + `(${tabsCount.completed !== false ? tabsCount.completed : "-"})`} tabClassName=" navLink pb-3">
									<ManuscriptsContent selectAllId="completedTabSelectAll" errorMessage={errorMessage} isDeleteAllLoading={isDeleteAllLoading} setIsDeleteAllLoading={setIsDeleteAllLoading} setIsCheckedAll={setIsCheckedAll} isCheckedAll={isCheckedAll} checkedVal={checkedVal} setCheckedVal={setCheckedVal}  manuscripts={manuscripts} isLoading={isLoading} status={status} handleRender={getManuscripts} isRefreshed={handleReset} setIsStatusChange={setIsStatusChange} />
									<Pagination
									className="p-2 mb-0 mt-2"
									currentPage={current}
									totalCount={totalRecords}
									pageSize={offset}
									onPageChange={(page) => handleCurrentPage(page)}
									handleGoToPagination={handleGoToPagination}
									pageNo={pageNo}
									setPageNo={setPageNo}
									inputId="formattedManuscriptPagination"
									/>
								</Tab>
								<Tab eventKey="Draft" title={"Drafts " + `(${tabsCount.darfts !== false ? tabsCount.darfts : "-"})`} tabClassName="navLink pb-3">
									<ManuscriptsContent selectAllId="darftTabSelectAll" errorMessage={errorMessage} isDeleteAllLoading={isDeleteAllLoading} setIsDeleteAllLoading={setIsDeleteAllLoading} setIsCheckedAll={setIsCheckedAll} isCheckedAll={isCheckedAll} checkedVal={checkedVal} setCheckedVal={setCheckedVal} manuscripts={manuscripts} isLoading={isLoading} status={status} handleRender={getManuscripts} isRefreshed={handleReset}/>
									<Pagination
									className="p-2 mb-0 mt-2"
									currentPage={current}
									totalCount={totalRecords}
									pageSize={offset}
									onPageChange={(page) => handleCurrentPage(page)}
									handleGoToPagination={handleGoToPagination}
									pageNo={pageNo}
									setPageNo={setPageNo}
									inputId="darftManuscriptPagination"
									/>
								</Tab>
								<Tab eventKey="Archived" title={"Archived " + `(${tabsCount.archived !== false ? tabsCount.archived : "-"})`} tabClassName="navLink pb-3">
									<ManuscriptsContent selectAllId="archivedTabSelectAll" errorMessage={errorMessage} isDeleteAllLoading={isDeleteAllLoading} setIsDeleteAllLoading={setIsDeleteAllLoading} setIsCheckedAll={setIsCheckedAll} isCheckedAll={isCheckedAll} checkedVal={checkedVal} setCheckedVal={setCheckedVal} manuscripts={manuscripts} isLoading={isLoading} status={status} handleRender={getManuscripts} isRefreshed={handleReset}/>
									<Pagination
									className="p-2 mb-0 mt-2"
									currentPage={current}
									totalCount={totalRecords}
									pageSize={offset}
									onPageChange={(page) => handleCurrentPage(page)}
									handleGoToPagination={handleGoToPagination}
									pageNo={pageNo}
									setPageNo={setPageNo}
									inputId="archivedManuscriptPagination"
									/>
								</Tab>
							</Tabs> 
						</section>
							</Col>
						</Row>
						
				</div>
		</div>
		)
}

export default MyDashboardPage;