import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import classNames from 'classnames';
import { Tabs, Tab, Card, Row, Col, InputGroup, FormControl, Spinner, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import styles from './Profile.module.scss';
import { dataDisplayRequest } from "../../utils/auth_service/AuthServiceHelper";
import { toast } from "react-toastify";
import LocalStorageService from "../../utils/auth_service/AuthServiceStorage";
import { authLogout } from "../../utils/auth_service/AuthServiceHelper";
import { tokenExpired } from '../../store/actions/RefreshTokenAction';
import history from "../../history";
import PaymentPage from "../PaymentPages/paymentPage";
import AxiosService from "../../utils/AxiosService";
import CreditPurchased from "./creditPurchased";
import CreditSpent from "./creditSpent";
import { Link } from 'react-router-dom';

function Profile(){

    const queryParams = new URLSearchParams(window.location.search)
    const [activeTab, setActiveTab] = useState(queryParams.get("tab") ?queryParams.get("tab") : 'general');
    const localStorageService = LocalStorageService.getService();

    const authDetails = JSON.parse(localStorageService.getAuthDetails());

    const [formValues, setFormValues] = useState({...authDetails?.metaData, "email": authDetails?.email});
    const [formErrors, setFormErrors] = useState({ firstName: '', lastName: '', currentAffiliation: '', orcidId: '',  phone: '', fax: '', designation: '', country: '' });
    
    const [passwordFormValues, setPasswordFormValues] = useState({oldPassword: "", newPassword: '', confirmPassword: ''});
    const [passwordFormErrors, setPasswordFormErrors] = useState({oldPassword: "", newPassword: '', confirmPassword: ''});

    const [newPasswordToggle, setNewPasswordToggle] = useState(false);
    const [oldPasswordToggle, setOldPasswordToggle] = useState(false);
    const [confirmPasswordToggle, setConfirmPasswordToggle] = useState(false);
    const [userPaymentProfile, setUserPaymentProfile] = useState(false)
    const [creditPurchased, setCreditPurchased] = useState(0)
    const [isOrtho, setIsOrtho] = useState(authDetails?.metaData?.receive_news_on_orthopublish === "Yes" ? true : false)
    const [isBnjQuaterly, setIsBnjQuaterly] = useState(authDetails?.metaData?.receive_news_and_offers_from_bnj === "Yes" ? true : false)

    const validEmailRegex = RegExp(
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
    );
    const validPassword = RegExp("\\s");
    const validText = RegExp(/^([^\s\d]+)+(\s{1}[^\s\d]+)*$/);
    const validPhoneNo = RegExp(/\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/)
    const noChar = RegExp(/^[^a-zA-Z]*$/)

    const apiService = new AxiosService()
    
    const [isLoading, setIsLoading] = useState(false);
    const [isPasswordLoading, setIsPasswordLoading] = useState(false);

    const path = window.location.pathname.includes("orthopub") ? window.location.pathname.split('/')[2] : window.location.pathname.split('/')[1];
    const tabRef = useRef()
    const dispatch = useDispatch();

    function changeActiveTab(tab){
        setActiveTab(tab);
    }

    function handleChange(event) {
        event.preventDefault();
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value});
    }
 
    function handleValidation(event){
        const {name, value} = event.target;
        typeof value === "string" ?
            setFormValues({...formValues, [name]: value.trim()})
        :
            setFormValues({...formValues, [name]: value})
        switch (name) {
            case "first_name":
                setFormErrors({
                    ...formErrors,
                    firstName: value?.length ===0 ? "* First Name is required": value?.length > 0 && value?.length < 2 ?  "First Name must be min. 2 characters.": !validText.test(value.trim()) ? "Numericals or white space not accepted": value?.length > 15? "First Name must be max. 15 characters": ""
                });
                break;
            case "last_name":
                setFormErrors({
                    ...formErrors,
                    lastName: value?.length === 0 ? "* Last Name is required": !validText.test(value.trim()) ? "Numericals or white space not accepted": value?.length>15? "Last Name must be max. 15 characters": ""
                });
                break;
            case "phone":
				let trimedPhoneVal = formValues?.phone?.split('').filter(v=> v !="-" && v!="+").join('')
                setFormErrors({
                    ...formErrors,
                    phone: trimedPhoneVal.length > 0 && trimedPhoneVal.length < 7 ?  "Phone number must be min. 7 digit." : formValues?.phone?.length > 0 ? !validPhoneNo.test(trimedPhoneVal) ? "Enter valid phone number" : !noChar.test(trimedPhoneVal) ? "Phone numbers must not include any alphabetical characters" : "" : ""
                });
                break;
            case "fax":
				    let trimedFaxVal = formValues?.fax?.split('').filter(v=> v !="-" && v!="+").join('')
                    setFormErrors({
                            ...formErrors,
                            fax:  trimedFaxVal.length>0 && trimedFaxVal.length < 7 ? "Fax must be min. 7 digit.": ""
                    });
                    break;
            case "designation":
                setFormErrors({
                        ...formErrors,
                        designation: value?.trim().length >0 && value?.trim().length<3 ? "Job title must be min. 3 characters.": ""
                });
                break;
            case "affiliation":
                setFormErrors({
                        ...formErrors,
                        currentAffiliation: value?.trim().length === 0 ? "* Company/Institution is required": value?.trim().length<=2 ? "Company/Institution must be min. 3 characters.": ""
                });
                break;
            case "country":
                setFormErrors({
                        ...formErrors,
                        country: value?.trim().length >0 && value?.trim().length<3 ? "Country min. 3 characters.": ""
                });
                break;
            default:
                break;
        }
    }

    function handleSubmit(event){
        event.preventDefault();
        if(formErrors.firstName || formErrors.lastName || formErrors.phone || formErrors.fax || formErrors.designation || formErrors.country){
            toast.error("Form submission contains errors - please check and correct.", {autoClose: 3000});
            return;
        }
        else{
            if(formValues?.first_name?.trim() && formValues?.last_name?.trim() && formValues?.affiliation?.trim().length >2){
                setIsLoading(true);
                let trimedFormValues = {
                    "first_name": formValues?.first_name?.trim(),
                    "last_name": formValues?.last_name?.trim(),
                    "email": formValues?.email?.trim().toLowerCase(),
                    "affiliation": formValues?.affiliation?.trim(),
                    "orcid": formValues?.orcid?.trim(),
                    "fax": formValues?.fax?.trim(),
                    "phone": formValues?.phone?.trim(),
                    "designation": formValues?.designation?.trim(),
                    "country": formValues?.country?.trim(),
                    "receive_news_and_offers_from_bnj": isBnjQuaterly ? "Yes" : "No",
                    "receive_news_on_orthopublish": isOrtho ? "Yes" : "No"
                }
                const config = {
                    method: "post",
                    url: "/api/v1/auth/update",
                    data: {userId: authDetails.userId, metaData: trimedFormValues}
                }
                dataDisplayRequest(config).then(response => {
                    setIsLoading(false);
                    localStorageService.setAuthDetails(JSON.stringify(response));
                    toast.success("Data updated successfully!",  {
                        autoClose: 3000,
                    });
                    setTimeout(()=>{
                        window.location.reload();
                    }, 2000)
                }).catch(err=> {
                    setIsLoading(false);
                    toast.error("Something went wrong", {autoClose: 3000})
                })
            }else{
                setFormErrors({
                    ...formErrors,
                    firstName: formValues.firstName?.length === 0 ?"* First Name is required": formValues.firstName?.length > 0 && formValues.firstName?.length < 2 ?  "First Name must be min. 2 characters." : !validText.test(formValues.firstName) ? "Numericals or white space not accepted": "",
                    lastName: formValues.lastName?.length ===0 ? '* Last Name is required':  !validText.test(formValues.lastName) ? "Numericals or white space not accepted":"",
                    currentAffiliation: formValues.affiliation?.trim().length ===0? "* Company/Institution is required": formValues.affiliation?.trim().length <=2 ? "Company/Institution must be min. 3 characters":""
                })
                toast.error("Form submission contains errors - please check and correct.", {autoClose: 3000})
            }
            
        }
        
    }

    function handlePasswordChange(event) {
        event.preventDefault();
        const { name, value } = event.target;
        setPasswordFormValues({ ...passwordFormValues, [name]: value });
    }
 
    function handlePasswordValidation(event){
        const { name, value } = event.target;
        switch (name) {
            case "oldPassword":
                setPasswordFormErrors({
                    ...passwordFormErrors,
                    [name]: value.length === 0 ?"*Old password is required": value.length < 6 ? "Old password must be 6 characters" : "",
                    newPassword: passwordFormValues.newPassword.length > 0 ? passwordFormValues.newPassword === value ? "Old Password & New Password can't be same" : "" : ""
                });
                break;
            case "newPassword":
                setPasswordFormErrors({
                    ...passwordFormErrors,
                    [name]: value.length > 0 ? (value.length > 5 ? (validPassword.test(value) ? "Whitespaces not allowed" : (value === passwordFormValues.oldPassword ? "Old Password & New Password can't be same" : "")) : "Password must be 6 characters") : "*New password is required!",
                    confirmPassword: passwordFormValues.confirmPassword.length > 0 ? value === passwordFormValues.confirmPassword ? "" : "Passwords didn't match" : ""
                });
                break;
            case "confirmPassword":
                setPasswordFormErrors({ ...passwordFormErrors, [name]:  value.length ===0 ?"Confirm password is required": value !== passwordFormValues.newPassword ? "Passwords didn't match" : ""})
                break;
            default:
                break;
        }
    }

    function handlePasswordSubmit(event){
        event.preventDefault();
         if(passwordFormValues.oldPassword.length ===0|| passwordFormValues.oldPassword.length<6 || passwordFormValues.newPassword.length ===0||passwordFormValues.newPassword.length <6 || passwordFormValues.confirmPassword.length ===0 || passwordFormValues.newPassword === passwordFormValues.oldPassword){
            setPasswordFormErrors({
                ...passwordFormErrors,
                oldPassword: passwordFormValues.oldPassword.length ===0 ? "* Old password is required!": passwordFormValues.oldPassword.length<6 ? "Old password must be 6 characters": "",
                newPassword: passwordFormValues.newPassword.length ===0? "* New password is required!":  passwordFormValues.newPassword.length <6 ? "Password must be 6 characters" : passwordFormValues.newPassword === passwordFormValues.oldPassword ?"Old Password & New Password can't be same": "",
                confirmPassword: passwordFormValues.confirmPassword.length ===0? "* Confirm password is required!":passwordFormValues.confirmPassword !== passwordFormValues.newPassword ? "Passwords didn't match": ""
            })
            toast.error("Form submission contains errors - please check and correct.", {autoClose: 3000});
            return;
        }
        else if(passwordFormErrors.oldPassword || passwordFormErrors.newPassword || passwordFormErrors.confirmPassword){
            toast.error("Form submission contains errors - please check and correct.", {autoClose: 3000});
            return;
        }
        else{
            setIsPasswordLoading(true);
            const config = {
                method: 'post',
                url: '/api/v1/auth/changePassword',
                data: {"oldPassword": passwordFormValues.oldPassword, "newPassword": passwordFormValues.newPassword, "confirmPassword": passwordFormValues.confirmPassword}
            }
            dataDisplayRequest(config).then(response => {
                setIsPasswordLoading(false);
                if(response.result || response.result === 'success'){
                    toast.success("New password set successfully",  {
                        autoClose: 3000,
                    });
                    onLogout();
                }
            }, error => {
                setIsPasswordLoading(false);
                toast.error(error.response.data.message, {autoClose: 3000}); 
            })
        }
    }

    function onLogout() {
        const params = new URLSearchParams();
        params.append("access_token", localStorageService.getAccessToken());
        const config = {
            url: "api/v1/auth/getLogout",
            method: "post",
            data: params
        };
        authLogout(config).then(response => {
            localStorageService.removeTokens();
            toast.success("Login again!",  {
                autoClose: 3000,
            });
            dispatch(tokenExpired());
            if(path === 'profile'){
                history.push('/search');
            }
        }).catch(error => {
            toast.error("Something went wrong!", {autoClose: 3000});
        });
    }

    function handleNumberChange(event){
        event.preventDefault();
        const {name, value} = event.target
        if(event.target.validity.valid){
            setFormValues({...formValues,[name]: value})
        }
        else if (value === '' || value === '-') {
            setFormValues({...formValues,[name]: value})
        }
    }

    const fetchUserPaymentProfile = () => {
        apiService.getPriceTable().then((priceTable) => {
            apiService.userPaymentProfile().then((res) => {
                setUserPaymentProfile({...res, priceDetails: priceTable.result})
            }).catch((err) => {
                toast.error(err.response.data.message, {autoClose: 3000})
            })
        }).catch((error) => {
            toast.error(error.response.data.message, {autoClose: 3000})
        })
    }

    const sideScroll = (element, speed, distance, step) => {
		let tabContainer = element?.children[0]
        let scrollAmount = 0;
        const slideTimer = setInterval(() => {
            tabContainer.scrollLeft += step;
            scrollAmount += Math.abs(step);
            if (scrollAmount >= distance) {
                clearInterval(slideTimer);
            }
        }, speed);
    };

    const handleTabChange = (tab) => {
        changeActiveTab(tab)
        history.push(`/profile?tab=${tab}`)
        if (tab === "general") {
            sideScroll(tabRef.current, 10, 500, -10)
        } else if (tab === "password") {
            if (activeTab === "general") {
                sideScroll(tabRef.current, 10, 80, 10)
            } else {
                sideScroll(tabRef.current, 10, 80, -10)
            }
        } else if (tab === "credits") {
            if (activeTab === "general" || activeTab === "password") {
                sideScroll(tabRef.current, 10, 80, 10)
            } else {
                sideScroll(tabRef.current, 10, 100, -10)
            }
        } else if (tab === "creditPurchased") {
            if (activeTab !== "creditSpent") {
                sideScroll(tabRef.current, 10, 100, 10)
            } else {
                sideScroll(tabRef.current, 10, 100, -10)
            }
        } else if (tab === "creditSpent") {
            sideScroll(tabRef.current, 10, 500, 10)
        }
    }

    useEffect(() => {
        fetchUserPaymentProfile()
    }, [creditPurchased])

    useEffect(() => {
		setTimeout(() => {
			sideScroll(tabRef.current, 10, 100, 10)
			setTimeout(() => {
				sideScroll(tabRef.current, 10, 100, -10)
			}, 300)
		}, 500)
	}, [])

    return <Card className="mt-3">
        <Card.Body>
            <div ref={tabRef}>
                <Tabs 
                    defaultActiveKey={activeTab} 
                    onSelect={(k) => {
                        handleTabChange(k)
                    }} 
                    className={styles.customTabset}
                > 
                    <Tab id="tab-general" eventKey='general' title='General' tabClassName={classNames(activeTab === 'general' ? styles.customActiveTab : styles.customTab)}>
                        <Row className="py-4">
                            <Col md={6}>
                            <form>

                                <label htmlFor="email">Email Address</label>
                                <FormControl
                                    id="email"
                                    aria-label="Email"
                                    type="email"
                                    name="email"
                                    className={styles.customInput}
                                    disabled
                                    value={formValues.email}
                                    // aria-describedby="basic-addon1"
                                />
                                <span className={styles.formErrors}></span>

                                <label htmlFor="first_name">First Name <span className="text-danger">*</span></label>
                                <FormControl
                                    id="first_name"
                                    type="text"
                                    aria-label="First Name"
                                    name="first_name"
                                    value={formValues.first_name}
                                    className={styles.customInput}
                                    placeholder="Enter your first name"
                                    onChange={event => handleChange(event)}
                                    onBlur={event => handleValidation(event)}
                                />
                                <span className={styles.formErrors}>{formErrors.firstName}</span>

                                <label htmlFor="last_name">Last Name <span className="text-danger">*</span></label>
                                <FormControl
                                    id="last_name"
                                    type="text"
                                    aria-label="Last Name"
                                    name="last_name"
                                    value={formValues.last_name}
                                    className={styles.customInput}
                                    placeholder="Enter your last name"
                                    onChange={event => handleChange(event)}
                                    onBlur={event => handleValidation(event)}
                                />
                                <span className={styles.formErrors}>{formErrors.lastName}</span>

                                <label htmlFor="affiliation">Company/Institution <span className="text-danger">*</span></label>
                                <FormControl
                                    id="affiliation"
                                    type="text"
                                    aria-label="Company/Institution"
                                    name="affiliation"
                                    value={formValues.affiliation}
                                    className={styles.customInput}
                                    placeholder="Enter Company/Institution"
                                    onChange={event => handleChange(event)}
                                    onBlur={event => handleValidation(event)}
                                />
                                <span className={styles.formErrors}>{formErrors.currentAffiliation}</span>
                                
                                <div className={styles.inputNumberStyle}>
                                <label htmlFor="designation">Job Title</label>
                                    <FormControl
                                        id="designation"
                                        type="text"
                                        aria-label="Job title"
                                        name="designation"
                                        value={formValues.designation}
                                        className={styles.customInput}
                                        placeholder="Enter your job title"
                                        onChange={event => handleChange(event)}
                                        onBlur={event => handleValidation(event)}
                                />
                                <span className={styles.formErrors}>{formErrors.designation}</span>
                                </div>

                                <div className={styles.inputNumberStyle}>
                                <label htmlFor="phone">Phone</label>
                                    <InputGroup className={classNames("mt-2")}>
                                        <FormControl
                                            id="phone"
                                            aria-label="Phone"
                                            type="tel"
                                            pattern="^[0-9+-]*$"
                                            name="phone"
                                            value={formValues.phone}
                                            onChange={event => handleNumberChange(event)}
                                            placeholder="Enter your phone number"
                                            onBlur={event => handleValidation(event)}
                                            // onKeyDown={(event)=> (event.keyCode === 69 || event.keyCode === 190 ) && event.preventDefault()}
                                            // aria-describedby="basic-addon2"
                                        />
                                    </InputGroup>
                                    <span className={styles.formErrors}>{formErrors.phone}</span>
                                    </div>

                                    <div className={styles.inputNumberStyle}>
                                    <label htmlFor="country">Country</label>
                                        <FormControl
                                            id="country"
                                            type="text"
                                            aria-label="Country"
                                            name="country"
                                            value={formValues.country}
                                            className={styles.customInput}
                                            placeholder="Enter country"
                                            onChange={event => handleChange(event)}
                                            onBlur={event => handleValidation(event)}
                                    />
                                    <span className={styles.formErrors}>{formErrors.country}</span>
                                    </div>

                                    {/* <div className={styles.inputNumberStyle}>
                                    <label htmlFor="fax">Fax</label>
                                    <InputGroup className={classNames("mt-2")}>
                                        <FormControl
                                            aria-label="Fax"
                                            type="tel"
                                            pattern="^[0-9+-]*$"
                                            name="fax"
                                            value={formValues.fax}
                                            onChange={event => handleNumberChange(event)}
                                            placeholder="Enter your fax number"
                                            onBlur={event => handleValidation(event)}
                                            // onKeyDown={(event)=> (event.keyCode === 69 || event.keyCode === 190 ) && event.preventDefault()}
                                            aria-describedby="basic-addon3"
                                        />
                                    </InputGroup>
                                    <span className={styles.formErrors}>{formErrors.fax}</span>
                                    </div> */}
                                <label htmlFor="orcid">ORCID ID</label>
                                <FormControl
                                    id="orcid"
                                    type="text"
                                    aria-label="ORCID ID"
                                    name="orcid"
                                    value={formValues.orcid}
                                    className={styles.customInput}
                                    placeholder="Enter your ORCID ID"
                                    onChange={event => handleChange(event)}
                                    onBlur={event => handleValidation(event)}
                                />
                                <span className={styles.formErrors}>{formErrors.orcidId}</span>

                                <div>
                                    <Form className='my-4'>
                                        <span className='pr-3'>Opt-in to receive news and updates about OrthoPublish from Bone & Joint via email</span>
                                        <Form.Check 
                                            // reverse
                                            aria-label="Opt-in to receive news and updates about OrthoPublish from Bone & Joint via email"
                                            className='form-switch d-inline'
                                            type="switch"
                                            id="ortho-switch"
                                            value={isOrtho}
                                            checked={isOrtho}
                                            onChange={()=> setIsOrtho(v=>!v)}
                                        />
                                    </Form>
                                    <Form className='my-4'>
                                        <span className='pr-3'>Opt-in to receive news, updates and special offers from Bone & Joint via email</span>
                                        <Form.Check 
                                            // reverse
                                            aria-label="Opt-in to receive news, updates and special offers from Bone & Joint via email"
                                            className='form-switch d-inline'
                                            type="switch"
                                            id="bnjQuaterly-switch"
                                            value={isBnjQuaterly}
                                            checked={isBnjQuaterly}
                                            onChange={() => setIsBnjQuaterly(v=>!v)}
                                        />
                                    </Form>
                                    <p className="my-4">To stop receiving marketing emails immediately, please click the Unsubscribe link in communication you receive from Bone & Joint or email <a href='mailto:info@boneandjoint.org.uk'>info@boneandjoint.org.uk</a> with your request.</p>
                                    <p className='mb-4'>
                                        Read <a href={process.env.PUBLIC_URL + "/terms-and-conditions"}>Terms & Conditions</a> and <a href={process.env.PUBLIC_URL + "/privacy-policy"}>Privacy Policy</a>.
                                    </p>
                                </div>
                                

                                {isLoading ? 
                                    <button disabled={isLoading}>
                                        <span>Loading
                                            <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                            <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                            <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                        </span>
                                    </button>
                                :   <button className="px-5" onClick={(event) => handleSubmit(event)}>Save Changes</button>}

                            </form>
                            </Col>
                        </Row>
                    </Tab>
                    <Tab id="tab-password" eventKey='password' title='Password' tabClassName={classNames(activeTab === 'password' ? styles.customActiveTab : styles.customTab)}>
                        <Row className="py-4">
                            <Col md={5}>
                            <form>
                        
                                <label htmlFor="oldPassword">Old Password <span className="text-danger">*</span></label>
                                <InputGroup>
                                    <FormControl
                                        id="oldPassword"
                                        type={oldPasswordToggle ? "text" : "password"}
                                        aria-label="Old Password"
                                        name="oldPassword"
                                        value={passwordFormValues.oldPassowrd}
                                        className={styles.customInput}
                                        placeholder="Enter your old password"
                                        onChange={event => handlePasswordChange(event)}
                                        onBlur={handlePasswordValidation}
                                        onKeyUp={event => handlePasswordValidation(event)}
                                    />
                                    <InputGroup.Append>
                                        <InputGroup.Text>
                                            {oldPasswordToggle ? <FontAwesomeIcon icon={faEye} onClick={() => setOldPasswordToggle(!oldPasswordToggle)} /> : <FontAwesomeIcon icon={faEyeSlash} onClick={(event) => setOldPasswordToggle(!oldPasswordToggle)} /> }
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                                <span className={styles.formErrors}>{passwordFormErrors.oldPassword}</span>

                                <label htmlFor="newPassword">New Password <span className="text-danger">*</span></label>
                                <InputGroup>
                                    <FormControl
                                        id="newPassword"
                                        type={newPasswordToggle ? "text" : "password"}
                                        aria-label="New Password"
                                        name="newPassword"
                                        value={passwordFormValues.newPassword}
                                        className={styles.customInput}
                                        placeholder="Enter your new password"
                                        onChange={event => handlePasswordChange(event)}
                                        onBlur={handlePasswordValidation}
                                        onKeyUp={event => handlePasswordValidation(event)}
                                    />
                                    <InputGroup.Append>
                                        <InputGroup.Text>
                                            {newPasswordToggle ? <FontAwesomeIcon icon={faEye} onClick={() => setNewPasswordToggle(!newPasswordToggle)} /> : <FontAwesomeIcon icon={faEyeSlash} onClick={(event) => setNewPasswordToggle(!newPasswordToggle)} /> }
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                                <span className={styles.formErrors}>{passwordFormErrors.newPassword}</span>

                                <label htmlFor="confirmPassword">Confirm Password <span className="text-danger">*</span></label>
                                <InputGroup className={classNames("mt-2", styles.customInput)}>
                                    <FormControl
                                        id="confirmPassword"
                                        aria-label="Confirm Password"
                                        type={confirmPasswordToggle ? "text" : "password"}
                                        name="confirmPassword"
                                        value={passwordFormValues.confirmPassword}
                                        onChange={event => handlePasswordChange(event)}
                                        placeholder="Confirm your new password"
                                        onBlur={event => handlePasswordValidation(event)}
                                        onKeyUp={handlePasswordValidation}
                                        // aria-describedby="basic-addon1"
                                    />
                                    <InputGroup.Append>
                                        <InputGroup.Text>
                                            {confirmPasswordToggle ? <FontAwesomeIcon icon={faEye} onClick={() => setConfirmPasswordToggle(!confirmPasswordToggle)} /> : <FontAwesomeIcon icon={faEyeSlash} onClick={(event) => setConfirmPasswordToggle(!confirmPasswordToggle)} /> }
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                                <span className={styles.formErrors}>{passwordFormErrors.confirmPassword}</span>

                                {isPasswordLoading ? 
                                    <button disabled={isPasswordLoading}>
                                        <span>Loading
                                            <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                            <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                            <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                        </span>
                                    </button>
                                :   <button className="px-5" onClick={(event) => handlePasswordSubmit(event)}>Save Changes</button>}

                            </form>
                            </Col>
                        </Row>
                    </Tab>
                    <Tab id="tab-Credits" eventKey="credits" title="Credits" tabClassName={classNames(activeTab === 'credits' ? styles.customActiveTab : styles.customTab)}>
                        <Row className="py-4">
                            <Col md={12}>
                                <PaymentPage userPaymentProfile={userPaymentProfile} setUserPaymentProfile={setUserPaymentProfile} creditPurchased={creditPurchased} setCreditPurchased={setCreditPurchased}/>
                            </Col>
                        </Row>
                    </Tab>
                    <Tab id="tab-Credits-purchased" eventKey="creditPurchased" title="Purchase History" tabClassName={classNames(activeTab === 'creditPurchased' ? styles.customActiveTab : styles.customTab)}>
                        <Row className="py-4">
                            <Col md={12}>
                                <CreditPurchased activeTab={activeTab}/>
                            </Col>
                        </Row>
                    </Tab>
                    <Tab id="tab-Credits-spent" eventKey="creditSpent" title="Usage History" tabClassName={classNames(activeTab === 'creditSpent' ? styles.customActiveTab : styles.customTab)}>
                        <Row className="py-4">
                            <Col md={12}>
                                <CreditSpent activeTab={activeTab}/>
                            </Col>
                        </Row>
                    </Tab>
                </Tabs>
            </div>
        </Card.Body>
    </Card>
}

export default Profile;