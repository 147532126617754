import { faAngleDown, faArchive, faClone, faDownload, faExternalLink, faFileAlt, faInfoCircle, faTrashCan, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import fileDownload from "js-file-download";
import React, { useRef, useState } from "react";
import { Col, Modal, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { CircleLoader, FieldLoader} from "../../../../components/Loader/CommonLoader";
import AxiosService from "../../../../utils/AxiosService";
import "./ManuscriptItem.scss";
import TooltipButton from "../../../../components/TooltipButton/TooltipButton";
import { useEffect } from "react";
import ProgressBar from 'react-bootstrap/ProgressBar';



const ManuscriptItem = ({manuscript, status, handleRender, isRefreshed, setIsStatusChange, checkedVal, setCheckedVal}) => {
	const apiService = new AxiosService();
	const actionRef = useRef()
	const history = useHistory();
	const [loader, setLoader] = useState({donwloadBtnLoader: false, jorunalBtnLoader: false, isDeleteBtnLoader: false, isArchiveBtnLoader: false, userPaymentProfileLoader: false});
	const [manuscriptStatus, setManuscriptStatus] = useState(manuscript.manuscriptStatus)
	const [previousStatus, setPrivousStatus] = useState(manuscript.manuscriptStatus)
	const [show, setShow] = useState(false);
	const [reformatLoader, setReformatLoader] = useState(false)
	const [isDelete, setIsDelete] = useState(false);
	const [isDropdownToggle, setisDropdownToggle] = useState(false);
	const now = Math.round((100 * manuscript?.stepStatus?.step) / 6)
	const editURL = `/manuscript-preview/${manuscript.manuscriptId}?stepStatus=6&journal=${encodeURIComponent(manuscript.journalTitle)}&status=${status}`

	const handleDownload = (manuscript) => {
		setLoader({...loader, donwloadBtnLoader: true});
		apiService.exportManuscript(manuscript.manuscriptId).then(res=> {
			setLoader({...loader, donwloadBtnLoader: false});
			let manuscriptName = manuscript.manuscriptTitle? manuscript.manuscriptTitle.split(" ",4).join("_"): ""
			let fileName = manuscript.journalTitle.split(" ").join("_") + "_"+ manuscriptName + ".zip";
			fileDownload(res, fileName);
			setisDropdownToggle(!isDropdownToggle)
			toast.success("Manuscript has been downloaded successfully.", {autoClose: 3000});
		}).catch(async err => {
			let data = JSON.parse(await err?.response?.data.text())
			toast.error(data?.message, {autoClose: 3000})
			setisDropdownToggle(!isDropdownToggle)
			setLoader({...loader, donwloadBtnLoader: false})
		});
	}

	const handleClone = (manuscriptId) => {
		setLoader({...loader, userPaymentProfileLoader: true})
		if(manuscript.templateType === "Generic") {
			history.push(`/select-journal?manuscriptId=${manuscriptId}&templateType=${manuscript.templateType}&clone=${true}`)
			setLoader({...loader, userPaymentProfileLoader: false})
		} else {
			apiService.userPaymentProfile().then((res) => {
				if (res.credit >= 1) {
					history.push(`/select-journal?manuscriptId=${manuscriptId}&templateType=${manuscript.templateType}&clone=${true}`)
					setLoader({...loader, userPaymentProfileLoader: false})
				} else {
					toast.error("You have insufficient credits for this action. Please go to the Credits tab on your profile page to purchase more.", {autoClose: 3000})
					setLoader({...loader, userPaymentProfileLoader: false})
				}
			}).catch((err) => {
				toast.error(err?.response?.data?.message)
				setLoader({...loader, userPaymentProfileLoader: false})
			})
		}
	}

	const updateManuscriptStatus = (status) =>{
		apiService.updateManuscriptStatus({
			"id": manuscript.manuscriptId,
			"status": status
		}).then(res=>{
			toast.success(res.result, {autoClose: 3000})
			setIsStatusChange(true);
		}).catch(err=> {
			toast.error("Invalid Status", {autoClose: 3000})
			setIsStatusChange(false);
		});
	}

	const handleReformatManuscript = (manuscript) => {
		updateManuscriptStatus("Rejected")
		setReformatLoader(true)
		if(manuscript.templateType === "Generic") {
			history.push(`/select-journal?manuscriptId=${manuscript.manuscriptId}&templateType=${manuscript.templateType}&clone=${true}`)
			setReformatLoader(false)
		} else {
			apiService.userPaymentProfile().then((res) => {
				if (res.credit >= 1) {
					history.push(`/select-journal?manuscriptId=${manuscript.manuscriptId}&templateType=${manuscript.templateType}&clone=${true}`)
					setReformatLoader(false)
				} else {
					toast.error("You have insufficient credits for this action. Please go to the Credits tab on your profile page to purchase more.", {autoClose: 3000})
					setReformatLoader(false)
				}
			}).catch((err) => {
				toast.error(err?.response?.data?.message)
				setReformatLoader(false)
			})
		}
	}

	const handleCancleReformat = (value) => {
		updateManuscriptStatus("Rejected")
		setShow(false)
	}
	const handleChange = (event) => {
		setPrivousStatus(manuscriptStatus)
		setManuscriptStatus(event.target.value);
		setIsStatusChange(false);
		if(event.target.value==="Rejected") {
			setShow(true);
		}else {
			updateManuscriptStatus(event.target.value)
		}
	}

	const handleDeleteManuscript = (manuscriptId)=> {
		setLoader({...loader, isDeleteBtnLoader: true})
		apiService.deleteManuscript({"id": manuscriptId}).then(res=>{
			setLoader({...loader, isDeleteBtnLoader: false})
			toast.success(res.result, {
				autoClose: 3000,
			});
			handleRender()

		}).catch(err=> {
			setLoader({...loader, isDeleteBtnLoader: false})
			toast.error(err?.response?.data?.error, {autoClose: 3000})

		})
	}

	const handleArchive = (manuscript) => {
		setLoader({...loader, isArchiveBtnLoader: true})
		apiService.archiveManuscript(manuscript.manuscriptId).then(res => {
			setLoader({...loader, isArchiveBtnLoader: false})
			toast.success(res.result, {autoClose: 3000});
			handleRender()
		}).catch(error => {
			setLoader({...loader, isArchiveBtnLoader: false})
			if(error?.response) {
				toast.error(error?.response?.data?.message, {autoClose: 3000})
			}
		})
	}

	const isChecked =(val)=>{
        return checkedVal.includes(val) ? true : false;
    }

    const onChange= (event) => {
        const item = event.target.value;
        const localIsChecked = event.target.checked;
        if (localIsChecked) {
            setCheckedVal([...checkedVal, item]);
        } else {
            setCheckedVal(checkedVal.filter((each) => each !== item));
        }
    }

	const initiatePayment = () => {
		// const body = {
		// 	journalId: manuscript.journalId,
		// 	purchaseType: "perFormat"
		// }
		// apiService.getStripeUrl(manuscript.manuscriptId, body).then((res) => {
		// 	window.location.replace(res.sessionURL)
		// }).catch((err) => {
		// 	toast.error(err?.response?.data?.message)
		// })
		history.push(`/paymentPage?journalTitle=${manuscript?.journalTitle}&journalId=${manuscript?.journalId}&manuscriptId=${manuscript?.manuscriptId}`)
	}

	const getDate = (dateString) => {
        let date = new Date(dateString)
        return date.toLocaleDateString("en-UK")
    }

	useEffect(()=>{
		const handler = (event)=> {
			if(!actionRef.current.contains(event.target)){
				setisDropdownToggle(false)
			}
		}
		document.addEventListener("mousedown", handler)

		return () => {
			document.removeEventListener("mousedown", handler)
		}
	})

	return(
		<Row className="border-bottom py-3" key={manuscript.manuscriptId}>
			<Col sm={8} md={9} className="d-flex">
				<div className="mr-2">
					<input
					 aria-label={manuscript.manuscriptTitle ? manuscript.manuscriptTitle: "Title not found"}
					 type="checkbox"
					 value={manuscript.manuscriptId}
					 checked={isChecked(manuscript.manuscriptId)}
					 onChange={e => onChange(e)} />
				</div>
				<div className="w-100">
						{manuscript?.paymentStatus === "closed" ?
							<TooltipButton tooltipMessage={status!=="Draft" ? "Go to manuscript preview page" : "Go to manuscript submission system."}>
								<Link to={status!=="Draft" ? `${editURL}` : `/manuscript-submission/${manuscript.manuscriptId}`} className={"itemLink"}>{manuscript.manuscriptTitle ? manuscript.manuscriptTitle: "Title not found"}</Link>
							</TooltipButton>
						:
							<TooltipButton tooltipMessage={status!=="Draft" ? "Go to manuscript preview page" : "Payment has not been completed, please complete payment."}>
								<span className="itemLinkDisabled text-secondary" onClick={() => {initiatePayment()}}>{manuscript.manuscriptTitle ? manuscript.manuscriptTitle: "Title not found"}</span>
							</TooltipButton>
						}
					{status!=="Completed" &&<p className="text-muted my-1 progressStatus"><span>{manuscript?.stepStatus?.step}/6</span> steps completed </p>}
					<p className="authorText mt-2 mb-1 text-uppercase">Formatted for:</p>
					<p className="mb-3">
					    <span>{getDate(manuscript.updateDate) || "NA"}</span>
						<span className="dateStyle">{manuscript.journalTitle || "NA"}</span>
						{status !== "Archived" &&<a className="ml-2 linkText" href={manuscript.articleSubmissionLink} target="_blank" aria-label="Go to the journal's submission system"><TooltipButton tooltipMessage={"Go to the journal's submission system"}><FontAwesomeIcon icon={faExternalLink} /></TooltipButton> </a>}
					</p>
					{status==="Completed" && 
						<div className="manuscriptStatusStyle">
						<p className="authorText mt-3 mb-2 text-uppercase">Status:</p>
							<form className="d-flex gap1 flex-row align-items-center">
								<TooltipButton tooltipMessage="Use these buttons to update your manuscript status">
									<div className="d-flex flex-row align-items-center">
										<input
										aria-label="Formatted"
										type="radio"
										value="Formatted"
										checked={manuscriptStatus==="Formatted"}
										onChange={(e)=>handleChange(e)}
										/> 
										<span className={manuscriptStatus==="Formatted" ?"":"textLight"}>Formatted</span>
									</div>
								</TooltipButton>
								<span className="lines"></span>

								<TooltipButton tooltipMessage="Use these buttons to update your manuscript status">
									<div className="d-flex flex-row align-items-center">
										<input
										aria-label="Submitted"
										type="radio"
										value="Submitted"
										checked={manuscriptStatus==="Submitted"}
										onChange={(e)=>handleChange(e)}
										/> 
										<span className={manuscriptStatus==="Submitted" ?"":"textLight"}> Submitted</span>
									</div>
								</TooltipButton>
								<span className="lines"></span>
								<TooltipButton tooltipMessage="Use these buttons to update your manuscript status">
									<div className="d-flex flex-row align-items-center">
										<input
										aria-label="Rejected"
										type="radio"
										value="Rejected"
										checked={manuscriptStatus==="Rejected"}
										onChange={(e)=>handleChange(e)}
										className="rejected"
										/>
										<span className={manuscriptStatus==="Rejected" ?"text-danger":"textLight"}> Rejected</span> 
									</div>
								</TooltipButton>
								<span className="lines"></span>
								<TooltipButton tooltipMessage="Use these buttons to update your manuscript status">
									<div className="d-flex flex-row align-items-center">
										<input
										aria-label="Revised"
										type="radio"
										value="Revised"
										checked={manuscriptStatus==="Revised"}
										onChange={(e)=>handleChange(e)}
										/> 
										<span className={manuscriptStatus==="Revised" ?"":"textLight"}> Revised</span>
									</div>
								</TooltipButton>
								<span className="lines"></span>
								<TooltipButton tooltipMessage="Use these buttons to update your manuscript status">
									<div className="d-flex flex-row align-items-center">
										<input
										aria-label="Published"
										type="radio"
										value="Published"
										checked={manuscriptStatus==="Published"}
										onChange={(e)=>handleChange(e)}
										/>
										<span className={manuscriptStatus==="Published" ?"":"textLight"}> Published</span>
									</div>
								</TooltipButton>
							</form>
						</div>
					}
				</div>
				<Modal show={show} onHide={()=> setShow(false)} dialogClassName="text-justify">
					<Modal.Header  className="h6 align-items-center">
						Reformat manuscript for another journal?
						<FontAwesomeIcon className="btn" icon={faXmark} onClick={() => {
							setManuscriptStatus(previousStatus)
							setShow(false)
						}}/>
					</Modal.Header>
					<Modal.Body>
						<p>Your current manuscript was rejected by the journal. Would you like to reformat this manuscript to submit to another journal?</p>
						<button className="btnOutline w-100" disabled={reformatLoader} onClick={()=> handleCancleReformat(manuscript)}>Change status to Rejected</button>
						<button className="w-100 mt-2" disabled={reformatLoader} onClick={()=> handleReformatManuscript(manuscript)}>{reformatLoader ? <Spinner animation="grow" size="sm"/> : "Reformat manuscript"}</button>
					</Modal.Body>
				</Modal>

				<Modal show={isDelete} onHide={()=> setIsDelete(false) } size="sm">
				<Modal.Header  className="h6">Delete warning!</Modal.Header>
					<Modal.Body>
						<p>Are you sure you want to delete <strong>{manuscript.manuscriptTitle}</strong>? Deleting it will permanently remove the manuscript and all associated files</p>
						<button className="btnOutline w-100" onClick={()=> setIsDelete(false)}>Cancel</button>
						{loader.isDeleteBtnLoader?<FieldLoader isLoading={loader.isDeleteBtnLoader} className="w-100 mt-2 btn-danger" /> :<button className="w-100 mt-2 btn-danger" onClick={()=>handleDeleteManuscript(manuscript.manuscriptId)}>Delete manuscript <FontAwesomeIcon icon={faTrashCan} /></button>}
					</Modal.Body>

				</Modal>
			</Col>
			<Col sm={4} md={3} className="text-right actionButtons">
				<div ref={actionRef}>
					<Dropdown  key={manuscript.manuscriptId}  autoClose="outside" show={isDropdownToggle}>
						<Dropdown.Toggle id="dropdown-autoclose-outside" onClick={() => setisDropdownToggle(!isDropdownToggle)} className="btnToggle btn btn-light btn-sm" disabled = {manuscript?.paymentStatus !== "closed"}>
							Actions <FontAwesomeIcon icon={faAngleDown} />
						</Dropdown.Toggle>
		
						<Dropdown.Menu className="p-0 mt-1">
							{status === "Draft" && 
								<Dropdown.Item className="px-2" disabled={loader.userPaymentProfileLoader} onClick={()=> history.push(`/manuscript-submission/${manuscript.manuscriptId}?type=edit`)}>
									<button className="btn-outline mr-2 border-0 p-0">
										<FontAwesomeIcon icon={faFileAlt}/>
									</button>
									Edit manuscript
								</Dropdown.Item>
							}
							{status !== "Draft" && 
								<Dropdown.Item className="px-2" disabled={loader.userPaymentProfileLoader} onClick={()=> history.push(`${editURL}`)}>
									<button className="btn-outline mr-2 border-0 p-0">
										<FontAwesomeIcon icon={faFileAlt}/>
									</button>
									View/edit manuscript
								</Dropdown.Item>
							}
							<Dropdown.Item className="px-2" disabled={loader.userPaymentProfileLoader} onClick={()=>setIsDelete(true)}>
								<button className="btnDanger mr-2 border-0 p-0" >
									<FontAwesomeIcon icon={faTrashCan} />
								</button>
								Delete manuscript
							</Dropdown.Item>
							{status !== "Archived" &&
								<Dropdown.Item className="px-2" disabled={loader.userPaymentProfileLoader} onClick={()=> handleArchive(manuscript)}>
									{loader.isArchiveBtnLoader ?
										<button className="btn-outline mr-2 border-0 p-0">
											<CircleLoader size="sm"/>
										</button>
									:
										<button className="btn-outline mr-2 border-0 p-0">
											<FontAwesomeIcon icon={faArchive} />
										</button> 
									}
									Archive
								</Dropdown.Item>
							}
							<Dropdown.Item className="px-2" disabled={loader.userPaymentProfileLoader} onClick={()=>handleClone(manuscript.manuscriptId)}>
								<button className="btn-outline mr-2 border-0 p-0">
									{loader.userPaymentProfileLoader ? <Spinner animation="grow" size="sm"/> : <FontAwesomeIcon icon={faClone} />}
								</button>
								Format for another journal
							</Dropdown.Item>
							<Dropdown.Item className="px-2" disabled={loader.userPaymentProfileLoader} onClick={()=> handleDownload(manuscript)}>
								{ loader.donwloadBtnLoader ?
									<button className="btn-outline p-0 border-0 mr-2">
										<CircleLoader size="sm"/>
									</button>
								:	
									<button className="btn-outline p-0 border-0 mr-2">
										<FontAwesomeIcon icon={faDownload} />
									</button>
								}
								Download
							</Dropdown.Item>
							<Dropdown.Item className="px-2" disabled={loader.userPaymentProfileLoader} onClick={()=> {window.open(manuscript.articleSubmissionLink,  '_blank')}}>
								<button className="btn-outline p-0 border-0 mr-2">
									<FontAwesomeIcon icon={faExternalLink} />
								</button>
								Go to the journal's submission system
							</Dropdown.Item> 					
						</Dropdown.Menu>
					</Dropdown>
				</div>
				{status === "Draft" ?
					manuscript?.paymentStatus === "closed" ? 
						"" 
					: 
						<>
							<p className="mt-1 mr-2 text-danger">Payment failed</p>
							<button onClick={() => {initiatePayment()}} className="btn btn-outline-warning btn-sm">Retry payment</button>
						</>
 				:
					""
				}
			</Col>
		</Row>
	)
}

export default ManuscriptItem;