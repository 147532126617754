import React from "react";
import AxiosService from "../../utils/AxiosService";
import { useState } from "react";
import { toast } from "react-toastify";
import { useEffect } from "react";
import Pagination from "../../components/PaginationComponent/Pagination";
import { Spinner } from "react-bootstrap";
import styles from "./Profile.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

const CreditPurchased = ({activeTab, userId}) => {
    const apiService = new AxiosService()
    const [userTransactions, setUserTransactions] = useState([])
    const [limit, setLimit] = useState(10)
    const [sortField, setSortField] = useState("transactionRequested")
    const [transactionStatus, setTransactionStatus] = useState("none")
    const [orderBy, setOrderBy] = useState("desc")
    const [current, setCurrent] = useState(1)
    const [page, setPage] = useState(1)
    const [templateType, setTemplateType] = useState("none")
    const [totalRecords, setTotalRecords] = useState(0)
    const [loaderControl, setLoaderControl] = useState({tableLoader: false})
    const [sortDropDownValue, setSortDropDownValue] = useState("mostRecentFirst")
    const [fetchTransaction, setFetchTransaction] = useState(false)
    const currencySymbols = {
        'USD': '$', // US Dollar
        'EUR': '€', // Euro
        'CRC': '₡', // Costa Rican Colón
        'GBP': '£', // British Pound Sterling
        'ILS': '₪', // Israeli New Sheqel
        'INR': '₹', // Indian Rupee
        'JPY': '¥', // Japanese Yen
        'KRW': '₩', // South Korean Won
        'NGN': '₦', // Nigerian Naira
        'PHP': '₱', // Philippine Peso
        'PLN': 'zł', // Polish Zloty
        'PYG': '₲', // Paraguayan Guarani
        'THB': '฿', // Thai Baht
        'UAH': '₴', // Ukrainian Hryvnia
        'VND': '₫', // Vietnamese Dong
    };

    const fetchUserTransactions = (resetPage) => {
        const queryParams = {
            start: resetPage ? 0 : current === 0 ? current : (current-1)*limit,
            limit: limit,
            sortField: sortField !== "none" ? sortField : "",
            orderBy: orderBy !== "none" ? orderBy : ""
        }
        const body = {
            status: transactionStatus !== "none" ? transactionStatus : "",
            templateType: templateType !== "none" ? templateType : ""
        }
        setLoaderControl({...loaderControl, tableLoader: true})
        if(userId?.length > 0) {
            body.userId = userId
            apiService.fetchUserPurchaseTransaction(body, queryParams).then((res) => {
                setUserTransactions(res.result)
                setTotalRecords(res.total)
                setLoaderControl({...loaderControl, tableLoader: false})
                setFetchTransaction(true)
            }).catch((err) => {
                toast.error(err.response.data.message, {autoClose: 3000})
                setLoaderControl({...loaderControl, tableLoader: false})
            })
        } else {
            apiService.fetchPurchasedTransaction(queryParams,body).then((res) => {
                setUserTransactions(res.result)
                setTotalRecords(res.total)
                setLoaderControl({...loaderControl, tableLoader: false})
                setFetchTransaction(true)
            }).catch((err) => {
                toast.error(err.response.data.message, {autoClose: 3000})
                setLoaderControl({...loaderControl, tableLoader: false})
            })
        }
    }

    const getDate = (dateString) => {
        let date = new Date(dateString)
        return date.toLocaleDateString("en-UK")
    }
    
    const getPurchaseType = (typeString) => {
        if(typeString === "perFormat" || typeString === "perFormatGeneric") {
            return "One time purchase 1 credit"
        } else if (typeString ==="couponCode") {
            return "Coupon code"
        } else if (typeString === "bulk6") {
            return "Bulk 6 credits"
        } else if (typeString === "bulk12") {
            return "Bulk 12 credits"
        }else if (typeString === "bulk24") {
            return "Bulk 24 credits"
        } else if (typeString === "perFormatSpecific") {
            return "One time purchase 2 credits"
        } else {
            return "N/A"
        }
    }

    const handleCurrentPage = (page) => {
        setCurrent(page)
        setPage(page)
    } 

    const handleGoToPagination = () => {
        let totalPage= Math.ceil(totalRecords / limit)
		if(page>0 && page<=totalPage){
            setPage(parseInt(page))
			setCurrent(parseInt(page))
		}
		else {
			toast.error("Please enter valid page number!", {autoClose: 3000})
		}
    }

    const getAmount = (item) => {
        if (item.amount) {
            if(item.currencyType) {
                return currencySymbols[item.currencyType.toUpperCase()] + item.amount
            } else {
                return currencySymbols["GBP"] + item.amount
            }
        } else {
            return false
        }
    }

    const setSortValue = (e) => {
        if(e.target.value === "mostRecentFirst") {
            setSortField("transactionRequested")
            setOrderBy("desc")
            setSortDropDownValue(e.target.value)
        } else if (e.target.value === "mostRecentLast") {
            setSortField("transactionRequested")
            setOrderBy("asc")
            setSortDropDownValue(e.target.value)
        } else if (e.target.value === "a-z") {
            setSortField("status")
            setOrderBy("asc")
            setSortDropDownValue(e.target.value)
        } else if (e.target.value === "z-a") {
            setSortField("status")
            setOrderBy("desc")
            setSortDropDownValue(e.target.value)
        } else {
            setSortField("transactionRequested")
            setOrderBy("desc")
            setSortDropDownValue("mostRecentFirst")
        }
    }

    useEffect(() => {
        if(current !== 1) {
            setCurrent(1)
            setPage(1)
        } else {
            if(fetchTransaction) {
                fetchUserTransactions(true)    
            }
        }
    }, [templateType, sortField, orderBy, transactionStatus])

    useEffect(() => {
        fetchUserTransactions(false)
    }, [current])

    useEffect(() => {
        if (activeTab !== "creditPurchased") {
            setCurrent(1)
            setPage(1)
            setTemplateType("none")
            setOrderBy("desc")
            setSortField("transactionRequested")
        }
    }, [activeTab])

    return (
        <div className="m-0 p-0">
            <div className={userId ? "m-3 row d-flex flex-row justify-content-between" : "row d-flex flex-row justify-content-between mb-3"}>
                <div className="d-none d-md-flex flex-row align-items-center col-md-6 col-sm-12"> {/* Transaction status for bigger screen */}
                    <div className="d-flex flex-column">
                        <label htmlFor="transactionStatus" className="text-secondary">Transaction status</label>
                        <select id="transactionStatus" className="form-control-sm form-control" value={transactionStatus} disabled={loaderControl.tableLoader} onChange={(e)=> {setTransactionStatus(e.target.value)}}>
                            <option value={"none"}>Select Transaction status</option>
                            <option value={"closed"}>Completed</option>
                            <option value={"open"}>Failed</option>
                        </select>
                    </div>
                </div>
                <div className="d-sm-flex d-md-none  flex-row align-items-center col-md-6 col-sm-12"> {/* Transaction status for smaller screen */}
                    <div className="d-flex flex-column">
                        <label htmlFor="transactionStatusSmall" className="text-secondary">Transaction status</label>
                        <select id="transactionStatusSmall" className="form-control-sm form-control" value={transactionStatus} disabled={loaderControl.tableLoader} onChange={(e)=> {setTransactionStatus(e.target.value)}}>
                            <option value={"none"}>Select Transaction status</option>
                            <option value={"closed"}>Completed</option>
                            <option value={"open"}>Failed</option>
                        </select>
                    </div>
                </div>


                <div className="d-none d-md-flex flex-row align-items-center justify-content-end mr-auto col-md-6 col-sm-12"> {/* Order by for bigger screen */}
                    <div>
                        <label htmlFor="OrderBy" className="text-secondary">Order by</label>
                        <select id="OrderBy" className="form-control-sm form-control" value={sortDropDownValue} disabled={loaderControl.tableLoader} onChange={(e)=> {setSortValue(e)}}>
                            <option value={"none"}>Select Order</option>
                            <option value={"mostRecentFirst"}>Most recent first</option>
                            <option value={"mostRecentLast"}>Most recent last</option>
                        </select>
                    </div>
                </div>
                <div className="d-sm-flex d-md-none flex-row align-items-center mt-3 mr-auto col-md-6 col-sm-12"> {/* Order by for smaller screen */}
                    <div>
                        <label htmlFor="orderBySmall" className="text-secondary">Order by</label>
                        <select id="orderBySmall" className="form-control-sm form-control" value={sortDropDownValue} disabled={loaderControl.tableLoader} onChange={(e)=> {setSortValue(e)}}>
                            <option value={"none"}>Select Order</option>
                            <option value={"mostRecentFirst"}>Most recent first</option>
                            <option value={"mostRecentLast"}>Most recent last</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="d-none d-md-block">
                { !loaderControl.tableLoader ? 
                    <table className="table">
                        <tr className="text-secondary">
                            <th>Date</th>
                            <th>Purchase Type</th>
                            <th>Amount</th>
                            <th>Credit</th>
                            <th>Transaction Status</th>
                            {userId?.length > 0 ? <th>Stripe ID</th> : null}
                        </tr>
                        {userTransactions.map((item) => {
                            return (
                                <tr className="small">
                                    <td>{getDate(item.transactionRequested) ? getDate(item.transactionRequested) : "-"}</td>
                                    <td>{getPurchaseType(item.purchaseType) ? getPurchaseType(item.purchaseType) : "-"}</td>
                                    <td>{getAmount(item) ? getAmount(item) : "-"}</td>
                                    <td>{item.credit ? item.credit : "-"}</td>
                                    <td className={item.status === "closed" ? "text-success" : item.status === "open" ? "text-danger" : ""}>
                                        { item.status === "closed" ? 
                                            <>
                                                <FontAwesomeIcon icon={faCircleCheck}/> Completed
                                            </> 
                                        : 
                                            item.status === "open" ? 
                                                <>
                                                    <FontAwesomeIcon icon={faExclamationCircle}/> Failed
                                                </>
                                            : 
                                                "-"
                                        }
                                    </td>
                                    {userId?.length > 0 ? <td className="text-break">{item?.chargeId ? item?.chargeId : "-"}</td> : null}
                                </tr>
                            )
                        })}
                    </table>
                :
                    <div className="d-flex flex-row justify-content-center mt-5 mb-5">
                        <Spinner animation="grow" className={styles.secondaryColor}/>
                    </div>
                }
            </div>
            <div className="d-sm-block d-md-none">
                { !loaderControl.tableLoader ? 
                    <>
                        {userTransactions.map((item) => {
                            return(
                                <div className={userId ? "mx-2 border rounded d-flex flex-row align-items-start my-2 row" : "border rounded d-flex flex-row align-items-start my-2 row"}>
                                    <ul style={{listStyleType: 'none'}} className="my-2">
                                        <li className="mb-2">
                                            <span className="w-25 text-secondary">Date: </span>
                                            <span className="w-75">{getDate(item.transactionRequested) ? getDate(item.transactionRequested) : "-"}</span>
                                        </li>
                                        <li className="mb-2">
                                            <span className="w-25 text-secondary">Purchase Type: </span>
                                            <span className="w-75">{getPurchaseType(item.purchaseType) ? getPurchaseType(item.purchaseType) : "-"}</span>
                                        </li>
                                        <li className="mb-2">
                                            <span className="w-25 text-secondary">Amount: </span>
                                            <span className="w-75">{getAmount(item) ? getAmount(item) : "-"}</span>
                                        </li>
                                        <li className="mb-2">
                                            <span className="w-25 text-secondary">Credit: </span>
                                            <span className="w-75">{item.credit ? item.credit : "-"}</span>
                                        </li>
                                        <li className="mb-2">
                                            <span className="w-25 text-secondary">Transaction Status: </span>
                                            <span className={item.status === "closed" ? "text-success w-75" : item.status === "open" ? "text-danger w-75" : "w-75"}>
                                                { item.status === "closed" ? 
                                                    <>
                                                        <FontAwesomeIcon icon={faCircleCheck}/> Completed
                                                    </> 
                                                : 
                                                    item.status === "open" ? 
                                                        <>
                                                            <FontAwesomeIcon icon={faExclamationCircle}/> Failed
                                                        </>
                                                    : 
                                                        "-"
                                                }
                                            </span>
                                        </li>
                                        {userId?.length > 0 ?
                                            <li className="mb-2">
                                                <span className="w-25 text-secondary">Stripe ID: </span>
                                                <span className="w-75 text-break">{item?.paymentIntentId ? item?.paymentIntentId : "-"}</span>
                                            </li>
                                        :
                                            null
                                        }
                                    </ul>
                                </div>
                            )
                        })}
                    </> 
                : 
                    <div className="d-flex flex-row justify-content-center mt-5 mb-5">
                        <Spinner animation="grow" className={styles.secondaryColor}/>
                    </div> 
                }
            </div>
            <Pagination
                className="p-2"
                currentPage={current}
                totalCount={totalRecords}
                pageSize={limit}
                onPageChange={(page) => handleCurrentPage(page)}
                handleGoToPagination={handleGoToPagination}
                pageNo={page}
                setPageNo={setPage}
                inputId="CreditPurchasePagination"
            />
        </div>
    )
}

export default CreditPurchased